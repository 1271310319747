import React from 'react'
import { useGetSliderCreditProductsSelector } from './selectors/useGetSliderCreditProductsSelector'
import {
  CreditSliderWrapper,
  CreditSlide,
  CreditSlideTitle,
  CreditSlideDesc,
  ProgressBarTextWrapper,
  ProgressBarText,
  ProgressBar,
  ProgressBarInner,
  TextWrapper,
  CreditSlideShimmer,
  TextNoWrap,
} from './styled'
import { Currency } from '@/components/Currency'
import { ApiStatus, useAppContext } from '@/core'
import { ClientProductsModel } from '@/api/products/clientProducts'
import { formatDate } from '@/utils/formatDate'
import { observer } from 'mobx-react-lite'
import { AppContext } from '@/models/AppContext'

export interface CreditSliderProps {
  productId: string
}

const getBgColor = (status: ClientProductsModel.CreditLoanGuiStatus): string => {
  if (
    status === ClientProductsModel.CreditLoanGuiStatus.PayOnlyEarlypaymentPaymentDay ||
    status === ClientProductsModel.CreditLoanGuiStatus.BothPaymentPaymentDay ||
    status === ClientProductsModel.CreditLoanGuiStatus.PaymentDay
  ) {
    return 'products/loanOrange'
  }
  if (
    status === ClientProductsModel.CreditLoanGuiStatus.OnlyPenalty ||
    status === ClientProductsModel.CreditLoanGuiStatus.OverduePayment
  ) {
    return 'products/loanRed'
  }
  return 'products/loanNew'
}

const toCurrentFormatDate = (date: string): string => {
  const parse = new Date(Date.parse(date))
  return formatDate(parse, 'D MMMM YYYY г.')
}

const getTitle = (
  status: ClientProductsModel.ContractStatus,
  loanBalance: number,
  creditAmount: number
): string | React.ReactNode => {
  if (
    status === ClientProductsModel.ContractStatus.Active ||
    status === ClientProductsModel.ContractStatus.Demanded
  ) {
    return <Currency amount={loanBalance} isShowPrecisionWithNullCents />
  }
  if (
    status === ClientProductsModel.ContractStatus.Signed ||
    status === ClientProductsModel.ContractStatus.Returned
  ) {
    return <Currency amount={creditAmount} isShowPrecisionWithNullCents />
  }

  return 'Кредит оплачен'
}

const getDescription = (status: ClientProductsModel.ContractStatus): string => {
  if (
    status === ClientProductsModel.ContractStatus.Active ||
    status === ClientProductsModel.ContractStatus.Demanded
  ) {
    return 'Осталось выплатить без процентов'
  }
  if (
    status === ClientProductsModel.ContractStatus.Signed ||
    status === ClientProductsModel.ContractStatus.Returned
  ) {
    return 'Договор подписан'
  }

  return 'Поздравляем!'
}

const getProgressBarText = (
  status: ClientProductsModel.ContractStatus,
  guiStatus: ClientProductsModel.CreditLoanGuiStatus,
  contractNumber,
  dateSign,
  dateClosure
): string | React.ReactNode => {
  if (status === ClientProductsModel.ContractStatus.Completed) {
    return (
      <TextWrapper>
        Кредит по договору №&nbsp;{contractNumber}, открытый{' '}
        <TextNoWrap>{toCurrentFormatDate(dateSign)},</TextNoWrap> был закрыт{' '}
        <TextNoWrap>{toCurrentFormatDate(dateClosure)}</TextNoWrap>
      </TextWrapper>
    )
  }
  if (
    status === ClientProductsModel.ContractStatus.Signed &&
    guiStatus === ClientProductsModel.CreditLoanGuiStatus.NoPaymentScheduleToNoPaymentScheduleDays
  ) {
    return 'Договор находится на пути в банк. Время доставки документов до 30 дней. После получения мы сформируем график платежей, у вас будет месяц для оплаты кредита.'
  }
  if (
    (status === ClientProductsModel.ContractStatus.Signed &&
      guiStatus ===
        ClientProductsModel.CreditLoanGuiStatus.NoPaymentScheduleOverNoPaymentScheduleDays) ||
    (status === ClientProductsModel.ContractStatus.Returned &&
      (guiStatus ===
        ClientProductsModel.CreditLoanGuiStatus.ContractReturnedToNoPaymentScheduleDays ||
        guiStatus ===
          ClientProductsModel.CreditLoanGuiStatus.ContractReturnedOverNoPaymentScheduleDays))
  ) {
    return 'Документы еще не поступили в банк, поэтому оплачивать кредит не нужно. Обратитесь в любой офис банка для повторного подписания договора.'
  }

  if (
    status === ClientProductsModel.ContractStatus.Sold &&
    guiStatus === ClientProductsModel.CreditLoanGuiStatus.SoldOut
  ) {
    return <TextWrapper>Кредит перешёл на обслуживание в Банк-партнёр</TextWrapper>
  }

  return ''
}

export const CreditSlider = observer(({ productId }: CreditSliderProps) => {
  const { productsInContext } = useAppContext<AppContext>()
  const { status, products } = useGetSliderCreditProductsSelector()

  if (status === ApiStatus.Pending) return <CreditSlideShimmer />

  const productById = products[productId]

  if (productById.productType === ClientProductsModel.ProductType.CreditLoan) {
    const { creditLoanGuiData, contractStatus, creditAmount } = productById
    const guiStatus = creditLoanGuiData.creditLoanGuiStatus
    const bgColor = getBgColor(guiStatus)

    const productInContext = productsInContext.arr.find((item) => item?.id === productId)

    const loanBalance = productInContext?.loanBalance || creditLoanGuiData.loanBalance

    const isActive = contractStatus === ClientProductsModel.ContractStatus.Active // 1
    const isSigned = contractStatus === ClientProductsModel.ContractStatus.Signed // 2
    const isReturned = contractStatus === ClientProductsModel.ContractStatus.Returned // 3
    const isCompleted = contractStatus === ClientProductsModel.ContractStatus.Completed // 4
    const isDemanded = contractStatus === ClientProductsModel.ContractStatus.Demanded // 5
    const isSoldOut = contractStatus === ClientProductsModel.ContractStatus.Sold // 6

    const progressBarText = getProgressBarText(
      contractStatus,
      guiStatus,
      productById.contractNumber,
      productById.dateSign,
      productById.dateClosure
    )

    return (
      <CreditSliderWrapper>
        <CreditSlide bgColor={isSoldOut ? 'products/undefined' : bgColor}>
          {isSoldOut ? (
            <CreditSlideTitle>
              <Currency amount={0} isShowPrecisionWithNullCents />
            </CreditSlideTitle>
          ) : (
            <div>
              <CreditSlideTitle>
                {getTitle(contractStatus, loanBalance, creditAmount)}
              </CreditSlideTitle>
              <CreditSlideDesc>{getDescription(contractStatus)}</CreditSlideDesc>
            </div>
          )}
          {(isActive || isDemanded) && (
            <div>
              <ProgressBarTextWrapper>
                <ProgressBarText>
                  Выплачено <Currency amount={productById.creditAmount - loanBalance} />
                </ProgressBarText>
                <ProgressBarText>
                  из <Currency amount={productById.creditAmount} />
                </ProgressBarText>
              </ProgressBarTextWrapper>
              <ProgressBar>
                <ProgressBarInner
                  completed={100 - productById.creditLoanGuiData.percentPaidBodyCredit}
                />
              </ProgressBar>
            </div>
          )}
          {(isSigned || isReturned || isCompleted || isSoldOut) && progressBarText && (
            <ProgressBarText isProgressBarText>{progressBarText}</ProgressBarText>
          )}
        </CreditSlide>
      </CreditSliderWrapper>
    )
  }

  return null
})

import { useEffect } from 'react'
import {
  useGetInstalmentSchedule,
  GetInstalmentScheduleModel,
} from '@/api/payment/getInstalmentSchedule'

export const useDialogPaymentScheduleState = (
  contractNumber: string,
  apiVersion: string,
  isEarly: boolean
): {
  data: GetInstalmentScheduleModel.Response
  isError: boolean
  isLoading: boolean
  retry: () => void
} => {
  const { data, mutateAsync, isError, isLoading } = useGetInstalmentSchedule()

  useEffect(() => {
    mutateAsync({
      contractNumber,
      apiVersion,
      isEarly,
    })
  }, [])

  return {
    data,
    isError,
    isLoading,
    retry: () =>
      mutateAsync({
        contractNumber,
        apiVersion,
        isEarly,
      }),
  }
}

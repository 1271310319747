import styled from '@emotion/styled'
import { Body14, Headline32 } from '@platform-ui/typography'
import { Shimmer } from '@platform-ui/components/Shimmer'

export const CreditSliderWrapper = styled.div(() => ({
  position: 'relative',
  margin: '32px 0 46px',
}))

export const CreditSlide = styled.div<{ bgColor: string }>(({ theme, bgColor }) => ({
  borderRadius: '28px',
  background: theme.color[bgColor],
  height: 200,
  maxWidth: 656,
  padding: 16,
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',

  [theme.media.md]: {
    height: 234,
    padding: 32,
  },
}))

export const CreditSlideTitle = styled(Headline32)(({ theme }) => ({
  fontWeight: 700,
  color: theme.color['text/tertiary'],

  [theme.media.md]: {
    fontSize: 40,
    lineHeight: '48px',
    letterSpacing: '-0.6px',
  },
}))

export const CreditSlideDesc = styled(Body14)(({ theme }) => ({
  marginTop: 8,
  color: theme.color['text/tertiaryTransparent'],
}))

export const ProgressBarTextWrapper = styled.div(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: 8,
}))

export const ProgressBarText = styled(Body14)<{ isProgressBarText?: boolean }>(
  ({ theme, isProgressBarText }) => ({
    color: theme.color['text/tertiaryTransparent'],

    [theme.media.md]: {
      marginLeft: isProgressBarText ? -8 : 0,
    },
  })
)

export const ProgressBar = styled.div(({ theme }) => ({
  width: '100%',
  height: '8px',
  background: theme.color['surface/lightLine'],
  borderRadius: 8,
}))

export const ProgressBarInner = styled.div<{ completed: number }>(({ theme, completed }) => ({
  width: `${completed}%`,
  height: '100%',
  background: theme.color['surface/main'],
  borderRadius: 8,
}))

export const TextWrapper = styled.span(() => ({
  maxWidth: 400,
  display: 'inline-block',
}))

export const CreditSlideShimmer = styled(Shimmer)(({ theme }) => ({
  borderRadius: '28px',
  height: 200,
  maxWidth: 656,
  margin: '32px auto 46px',

  [theme.media.md]: {
    height: 234,
  },
}))

export const TextNoWrap = styled.span(() => ({
  whiteSpace: 'nowrap',
}))

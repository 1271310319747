import React, { useEffect } from 'react'
import { NextPayment } from './containers/NextPayment'
import { History } from './containers/History'
import { useGetCreditProductById } from './selectors/useGetCreditProductById'
import { ApiStatus } from '@/core'
import { TabBar } from './containers/TabBar'
import { SoldOutLoanInfo } from './components/SoldOutLoanInfo'
import { ClientProductsModel } from '@/api/products/clientProducts'
import { TabBarShimmer } from './components/TabBarShimmer'
import { NextPaymentShimmer } from './components/NextPaymentShimmer'
import { useSettingsQuery } from '@/api/settings/settings'
import { ResponseType } from '@/models/ResponseType'
import { useAppContext } from '../../core'
import { AppContext } from '../../models/AppContext'
import { observer } from 'mobx-react-lite'

export interface CreditDetailsProps {
  productId: string
}

export const CreditDetails = observer(({ productId }: CreditDetailsProps) => {
  const { status, productById } = useGetCreditProductById(productId)
  const { data: settingsData } = useSettingsQuery()
  const { popups, eventBus } = useAppContext<AppContext>()

  useEffect(() => {
    if (popups.screens?.credit.length > 0) {
      eventBus.popupModal.open.emit({
        popup: popups.screens.credit[0],
      })
    }
  }, [popups])

  if (status === ApiStatus.Pending)
    return (
      <>
        <TabBarShimmer />
        <NextPaymentShimmer />
      </>
    )

  const {
    contractNumber,
    productName,
    productTypeNumber,
    creditAmount,
    accountNumber,
    dateSign,
    smsPackService,
    accountBalance,
    earlyPaymentSum,
    earlyPaymentType,
    creditLoanGuiData,
    penaltySum,
    contractStatus,
    payment,
    rassrochkaPlus,
    autoCins,
    soldOutDate,
  } = productById

  const soldOutCreditDescription: string =
    settingsData?.type === ResponseType.ResolveWithData &&
    settingsData.result.soldOutCreditDescription

  const skbDetailsButtonUrl: string =
    settingsData?.type === ResponseType.ResolveWithData &&
    settingsData.result?.skbCessionParams?.skbDetailsButtonUrl

  const { nextPaymentDateDisplayed, sumToPay, isUncertainPaymentSum } =
    productById.contract.properties

  const isEarly = rassrochkaPlus || autoCins

  const productDetails: Pick<
    ClientProductsModel.CreditLoan,
    | 'contractNumber'
    | 'productName'
    | 'productTypeNumber'
    | 'creditAmount'
    | 'accountNumber'
    | 'dateSign'
    | 'smsPackService'
    | 'accountBalance'
  > = {
    contractNumber,
    productName,
    productTypeNumber,
    creditAmount,
    accountNumber,
    dateSign,
    smsPackService,
    accountBalance,
  }

  return (
    <>
      {productById.isShowTabBar && (
        <TabBar
          accountNumber={productById.accountNumber}
          isEarly={isEarly}
          productId={productId}
          productById={productDetails}
          scheduleAvailable={productById.creditLoanGuiData.scheduleAvailable}
          showDetails={productById.creditLoanGuiData.showDetails}
          contractStatus={contractStatus}
          soldOutDate={soldOutDate}
          creditLoanGuiData={productById.creditLoanGuiData}
        />
      )}

      {productById.contractStatus === ClientProductsModel.ContractStatus.Sold && (
        <SoldOutLoanInfo
          soldOutCreditDescription={soldOutCreditDescription}
          skbDetailsButtonUrl={skbDetailsButtonUrl}
          soldOutDate={soldOutDate}
          ownerId={productById?.owner?.id}
        />
      )}

      {productById.isShowNextPayment && (
        <NextPayment
          nextPaymentDateDisplayed={nextPaymentDateDisplayed}
          contractNumber={contractNumber}
          sumToPay={sumToPay}
          earlyPaymentSum={earlyPaymentSum}
          earlyPaymentType={earlyPaymentType}
          creditLoanGuiData={creditLoanGuiData}
          penaltySum={penaltySum}
          isUncertainPaymentSum={isUncertainPaymentSum}
          contractStatus={contractStatus}
          accountBalance={accountBalance}
          productId={productId}
          payment={payment}
        />
      )}
      {productById.isShowHistory && <History contractNumber={productById.contractNumber} />}
    </>
  )
})

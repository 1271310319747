import React from 'react'
import { ContentHeader } from '@/components/ContentHeader'
import { useGetCreditPageProductsSelector } from '@/containers/CreditPage/selectors/useGetCreditPageProductsSelector'
import { useRouter } from '@/next/router'
import { ApiStatus, useAppContext } from '@/core'
import { CreditDetails } from '@/features/credit-details'
import { CreditSlider } from '@/features/credit-slider'
import { ContentError } from '@/components/ContentError'
import { AppContext } from '@/models/AppContext'

export const CreditPage = () => {
  const router = useRouter()
  const context = useAppContext<AppContext>()
  const productId = router.query.productId as string
  const { status, products } = useGetCreditPageProductsSelector()

  const isLoading = status === ApiStatus.Pending
  const isServerError = status === ApiStatus.Rejected
  const isProductNotFound = status === ApiStatus.Fulfilled && !products[productId]

  if (
    (!products[productId] && !isLoading) ||
    isServerError ||
    isProductNotFound ||
    !context.config.features.creditLoan_OB_15568
  ) {
    return <ContentError />
  }

  const productById = products[productId]
  const title = isLoading ? '' : productById.productName
  const description = isLoading
    ? ''
    : `${productById.description} ${
        productById.creditLoanGuiData.interestRate
          ? productById.creditLoanGuiData.interestRate + '%'
          : ''
      }`

  return (
    <>
      <ContentHeader isLoading={isLoading} title={title} description={description} />
      <CreditSlider productId={productId} />
      <CreditDetails productId={productId} />
    </>
  )
}

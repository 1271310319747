import React, { useState } from 'react'
import { TabList } from '@/components/TabList'
import { InfoSecondaryIcon } from '@platform-ui/icons/InfoSecondaryIcon'
import { DocsIcon } from '@platform-ui/icons/DocsIcon'
import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import { observer } from 'mobx-react-lite'
import { ClientProductsModel } from '@/api/products/clientProducts'
import { TimeInstallmentIcon, DepositSecondaryIcon } from '@platform-ui/icons'
import { DialogBankDetails } from '../DialogBankDetails'
import { DialogCreditDetails } from '../DialogCreditDetails'
import { DialogPaymentSchedule } from '../DialogPaymentSchedule'
import { useRouter } from '@/next/router'
import { TRANSACTION_TYPE } from '@/features/transfers-list/constants'

export enum TabListId {
  PaymentSchedule = 'payment-schedule',
  Refill = 'refill',
  Details = 'details',
  More = 'more',
}

export type TabListItem = {
  id: string
  title: string
  titleModal: string
  icon: React.ReactNode
  isDisabled?: boolean
}

export const getTabList = (
  isMobile: boolean,
  scheduleAvailable: boolean,
  showDetails: boolean,
  isSoldOutCredit: boolean
) => {
  const tabList: TabListItem[] = [
    {
      id: isSoldOutCredit ? TabListId.Refill : TabListId.PaymentSchedule,
      title: isSoldOutCredit ? 'Пополнить' : 'График',
      titleModal: isSoldOutCredit ? 'Пополнить' : 'График платежей',
      icon: isSoldOutCredit ? (
        <DepositSecondaryIcon color="icons/primary" size={isMobile ? 's12' : 's16'} />
      ) : (
        <TimeInstallmentIcon
          color={scheduleAvailable ? 'icons/primary' : 'icons/disabled'}
          size={isMobile ? 's12' : 's16'}
        />
      ),
      isDisabled: !isSoldOutCredit && !scheduleAvailable,
    },
    {
      id: TabListId.Details,
      title: 'Реквизиты',
      titleModal: 'Реквизиты счёта',
      icon: <DocsIcon color="icons/primary" size={isMobile ? 's12' : 's16'} />,
    },
    {
      id: TabListId.More,
      title: 'Подробнее',
      titleModal: 'Информация о кредите',

      icon: (
        <InfoSecondaryIcon
          color={showDetails ? 'icons/primary' : 'icons/disabled'}
          size={isMobile ? 's12' : 's16'}
        />
      ),
      isDisabled: !showDetails,
    },
  ]

  return {
    tabList,
  }
}

export interface TabBarProps {
  accountNumber: string
  productById?: Pick<
    ClientProductsModel.CreditLoan,
    | 'contractNumber'
    | 'productName'
    | 'productTypeNumber'
    | 'creditAmount'
    | 'accountNumber'
    | 'dateSign'
    | 'smsPackService'
    | 'accountBalance'
    | 'status'
    | 'owner'
    | 'contractStatus'
  >
  productId: string
  scheduleAvailable: boolean
  showDetails: boolean
  isEarly: boolean
  contractStatus: ClientProductsModel.ContractStatus
  creditLoanGuiData: ClientProductsModel.CreditLoanGuiData
}

const getActiveTab = (activeTabId: string, tabList: TabListItem[]) =>
  tabList.find(({ id }) => id === activeTabId)

export const TabBar = observer(
  ({
    accountNumber,
    productById,
    scheduleAvailable,
    showDetails,
    contractStatus,
    isEarly,
  }: TabBarProps) => {
    const { config, eventBus } = useAppContext<AppContext>()
    const router = useRouter()
    const productId = router.query.productId
    const isMobile = config.device.isMobile
    const isSoldOutCredit = contractStatus === ClientProductsModel.ContractStatus.Sold

    const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
    const [activeTabId, setActiveTabId] = useState<string>('')

    const { tabList } = getTabList(isMobile, scheduleAvailable, showDetails, isSoldOutCredit)

    const activeTab = getActiveTab(activeTabId, tabList)
    const title = activeTab ? activeTab.titleModal : ''

    const onOpen = (id: string) => () => {
      if (isSoldOutCredit && id === TabListId.Refill) {
        eventBus.transfersModal.open.emit({
          productId,
          isSoldOutCredit,
          type: TRANSACTION_TYPE.betweenAccounts,
        })

        return
      }

      setActiveTabId(id)
      setIsOpenModal(true)
    }

    const onClose = (): void => {
      setActiveTabId('')
      setIsOpenModal(false)
    }

    return (
      <>
        <TabList data={tabList} onOpen={onOpen} />
        {!isSoldOutCredit && (
          <DialogPaymentSchedule
            isEarly={isEarly}
            isOpen={isOpenModal && activeTabId === TabListId.PaymentSchedule}
            onClose={onClose}
            title={title}
            contractStatus={contractStatus}
            contractNumber={productById.contractNumber}
          />
        )}
        <DialogBankDetails
          accountNumber={accountNumber}
          isOpen={isOpenModal && activeTabId === TabListId.Details}
          onClose={onClose}
          title={title}
        />
        <DialogCreditDetails
          onClose={onClose}
          isOpen={isOpenModal && activeTabId === TabListId.More}
          title={title}
          accountNumber={accountNumber}
          product={productById}
          isSoldOutCredit={isSoldOutCredit}
        />
      </>
    )
  }
)

import React from 'react'
import { useDialogPaymentScheduleState } from './useDialogPaymentScheduleState'
import { ResponseType } from '@/models/ResponseType'
import { GetInstalmentScheduleModel } from '@/api/payment/getInstalmentSchedule'
import { PaymentSchedule } from '@/components/PaymentSchedule'
import { CheckIcon } from '@platform-ui/icons/CheckIcon'
import { TimeIcon } from '@platform-ui/icons/TimeIcon'
import { AboutSecurityIcon } from '@platform-ui/icons/AboutSecurityIcon'
import { BaseModal } from '@platform-ui/components'
import { ShimmerWrapper, ShimmerTitle, ShimmerDesc, PaymentScheduleWrapper } from './styled'
import { ErrorDataLoadingModal } from '@/components/ErrorDataLoadingModal'
import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import { RequirementPayment } from './components/RequirementPayment'

const getStatusInfo = (
  instalmentStatus: GetInstalmentScheduleModel.InstalmentStatus
): {
  statusIcon: React.ReactNode
  iconBgColor: string
  statusText: string
  statusBgColor: string
} => {
  if (instalmentStatus === GetInstalmentScheduleModel.InstalmentStatus.Paid) {
    return {
      statusIcon: <CheckIcon />,
      iconBgColor: 'icons/colors/success',
      statusText: 'Оплачен',
      statusBgColor: 'surface/lightSuccess',
    }
  }
  if (instalmentStatus === GetInstalmentScheduleModel.InstalmentStatus.UnPaid) {
    return {
      statusIcon: <AboutSecurityIcon />,
      iconBgColor: 'icons/colors/error',
      statusText: 'Просрочен',
      statusBgColor: 'surface/lightError',
    }
  }
  if (instalmentStatus === GetInstalmentScheduleModel.InstalmentStatus.Pending) {
    return {
      statusIcon: '',
      iconBgColor: '',
      statusText: 'Ежем. платёж',
      statusBgColor: 'surface/tertiary',
    }
  }
  if (instalmentStatus === GetInstalmentScheduleModel.InstalmentStatus.Future) {
    return {
      statusIcon: <TimeIcon />,
      iconBgColor: 'icons/primary',
      statusText: 'Ожидает списания',
      statusBgColor: 'surface/secondary',
    }
  }

  return {
    statusIcon: null,
    iconBgColor: '',
    statusText: '',
    statusBgColor: '',
  }
}

export interface DialogPaymentScheduleProps {
  contractNumber: string
  isOpen?: boolean
  isEarly: boolean
  onClose?: () => void
  title?: string
  contractStatus: number
}

export const DialogPaymentSchedule = ({
  contractNumber,
  isOpen,
  onClose,
  title,
  contractStatus,
  isEarly,
}: DialogPaymentScheduleProps) => {
  const context = useAppContext<AppContext>()

  const { data, isError, isLoading, retry } = useDialogPaymentScheduleState(
    contractNumber,
    context.config.apiVersion,
    isEarly
  )

  if (isLoading) {
    return (
      <BaseModal
        isOpen={isOpen}
        onClose={onClose}
        title={title}
        primaryBtnTitle=""
        primaryBtnClick={onClose}
        isPending
      >
        {Array.from({ length: 5 }).map((_, number) => (
          <ShimmerWrapper key={number}>
            <ShimmerTitle />
            <ShimmerDesc />
          </ShimmerWrapper>
        ))}
      </BaseModal>
    )
  }

  if (isError) {
    return (
      <BaseModal
        isOpen={isOpen}
        onClose={onClose}
        title={title}
        primaryBtnTitle="Попробовать ещё раз"
        primaryBtnClick={retry}
      >
        <ErrorDataLoadingModal description="Не удалось загрузить график платежей. Попробуйте ещё раз или повторите попытку позже" />
      </BaseModal>
    )
  }

  if (data?.data?.type === ResponseType.ResolveWithData) {
    return (
      <BaseModal
        isOpen={isOpen}
        onClose={onClose}
        title={title}
        primaryBtnTitle="Закрыть"
        primaryBtnClick={onClose}
      >
        {contractStatus == 5 && (
          <RequirementPayment debt={data.data.debt} detail={data.data.detail} />
        )}
        <PaymentScheduleWrapper>
          {data.data.detail.map((item) => {
            const { statusIcon, iconBgColor, statusText, statusBgColor } = getStatusInfo(
              item.instalmentStatus
            )

            return (
              <PaymentSchedule
                key={item.instalmentNumber}
                instalmentNumber={item.instalmentNumber}
                dueDate={item.dueDateFormat}
                instalmentAmount={item.instalmentAmount}
                statusIcon={statusIcon}
                iconBgColor={iconBgColor}
                statusText={statusText}
                statusBgColor={statusBgColor}
                debtMain={item.debtMain}
                interestSum={item.interestSum}
                serviceAmount={item.serviceAmount}
              />
            )
          })}
        </PaymentScheduleWrapper>
      </BaseModal>
    )
  }

  return null
}

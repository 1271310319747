import styled from '@emotion/styled'
import { Body14, Headline20 } from '@platform-ui/typography'
import { IconWrapper } from '@/components/IconWrapper'

export const WidgetWrapper = styled.div(({ theme }) => ({
  padding: 16,
  background: theme.color['surface/secondary'],
  borderRadius: '20px',
  minWidth: 212,

  [`${theme.media.md}`]: {
    padding: 32,
    minWidth: 360,
  },
}))

export const WidgetWrapperInner = styled.div(() => ({
  display: 'flex',
  flexWrap: 'wrap',
}))

export const WidgetContent = styled.div<{ isRepaymentCompleted: boolean; order?: number }>(
  ({ theme, isRepaymentCompleted, order }) => {
    return {
      paddingRight: 10,
      order: isRepaymentCompleted ? order : 'inherit',
      minWidth: '50%',

      '&:last-of-type': {
        paddingRight: 0,
        marginLeft: isRepaymentCompleted ? 'auto' : 0,
        textAlign: isRepaymentCompleted ? 'right' : 'left',
      },

      [`${theme.media.md}`]: {
        order: 'inherit',
        minWidth: '25%',

        '&:last-of-type': {
          marginLeft: 'auto',
          textAlign: 'right',
        },
      },
    }
  }
)

export const WidgetTitle = styled(Headline20)(({ theme }) => ({
  margin: '8px 0 16px',

  [`${theme.media.md}`]: {
    lineHeight: '28px',
    margin: '8px 0 32px',
  },
}))

export const WidgetDesc = styled(Body14)(({ theme }) => {
  return {
    fontSize: 12,
    lineHeight: '16px',
    color: theme.color['text/secondary'],
  }
})

export const WidgetIconButton = styled(IconWrapper)(() => ({
  width: 54,
  height: 54,
}))

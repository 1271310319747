import React from 'react'
import { Headline16 } from '@platform-ui/typography'
import { DialogBankDetailsCell, DialogBankDetailsTitle } from './styled'

export interface GetInfoInterbankTransferDataProps {
  clientFio: string
  corrAcc: string
  bankName: string
  bankNum: string
  bankINN: string
  accountNumber: string
  isOpen?: boolean
  onClose?: () => void
  title?: string
}

export const DialogBankDetailsSuccess = ({
  clientFio,
  bankName,
  corrAcc,
  bankNum,
  bankINN,
  accountNumber,
}: GetInfoInterbankTransferDataProps) => {
  const arr: {
    title: string
    description: string
  }[] = [
    {
      title: 'Получатель',
      description: clientFio,
    },
    {
      title: 'Счёт',
      description: accountNumber,
    },
    {
      title: 'Банк получателя',
      description: bankName,
    },
    {
      title: 'Корреспондентский счёт',
      description: corrAcc,
    },
    {
      title: 'БИК',
      description: bankNum,
    },
    {
      title: 'ИНН',
      description: bankINN,
    },
  ]

  return (
    <>
      {arr.map((item) => {
        return (
          <DialogBankDetailsCell key={item.title}>
            <DialogBankDetailsTitle>{item.title}</DialogBankDetailsTitle>
            <Headline16>{item.description}</Headline16>
          </DialogBankDetailsCell>
        )
      })}
    </>
  )
}

import styled from '@emotion/styled'
import { Headline16, Body12, Headline20, Headline14 } from '@platform-ui/typography'
import { Button } from '@platform-ui/components/Button'

export const DialogButton = styled(Button)(({ theme }) => ({
  width: '100%',
  margin: '0 auto',
}))

export const DialogTitle = styled(Headline16)(({ theme }) => ({
  height: 24,
  fontWeight: 600,
  color: theme.color['text/main'],
}))

export const DialogMain = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
}))

export const DialogSubTitle = styled(Body12)(({ theme }) => ({
  color: theme.color['text/secondary'],
  marginBottom: 8,
  fontWeight: 500,
}))

export const CurrenceWrapper = styled(Headline20)(({ theme }) => ({
  color: theme.color[`text/main`],
  fontSize: 20,
  fontWeight: 700,
}))

export const DialogNextPaymentWrapper = styled.div<{
  bgColor: 'surface/lightAttention' | 'surface/lightError' | 'surface/tertiary'
}>(({ theme, bgColor }) => ({
  // TODO: add color to the library
  background: bgColor === 'surface/lightAttention' ? '#FFF0E6' : theme.color[`${bgColor}`],
  marginTop: 8,
  marginBottom: 24,
  borderRadius: 16,
  padding: '16px 16px 20px 16px',
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',

  [`${theme.media.sm}`]: {
    marginTop: 0,
  },
}))

export const PaymentInfoWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
}))

export const PaymentItem = styled.div<{ isEnoughMoney?: boolean; withLeftSpacing?: boolean }>(
  ({ theme, isEnoughMoney, withLeftSpacing }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minWidth: 160,
    marginTop: 16,
    marginLeft: withLeftSpacing ? 24 : 0,
    borderRight: isEnoughMoney === false ? `0.5px solid ${theme.color['surface/bravo']}` : 'none',

    [`${theme.media.md}`]: {
      marginLeft: withLeftSpacing ? 48 : 0,
    },
  })
)

export const ListItem = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  '&:not(:last-child)': {
    borderBottom: `1px solid ${theme.color['surface/line']}`,
  },
}))

export const PaymentType = styled(Headline14)(({ theme }) => ({
  marginBottom: 16,
  marginTop: 16,
  color: theme.color['text/secondary'],
}))

export const PaymentAmount = styled(Headline16)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

export const DateInfo = styled(Headline20)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.color['text/main'],
}))

export const InfoMessageWrapper = styled(Headline16)(() => ({
  fontWeight: 600,
  lineHeight: 24,
  marginBottom: 32,
  display: 'flex',
  alignItems: 'center',
}))

export const IconWrapper = styled.div<{ bgColor?: 'error' | 'primary'; marginRight?: number }>(
  ({ theme, bgColor, marginRight }) => ({
    background: bgColor
      ? theme.color[`surface/${bgColor}`]
      : theme.color['text/tertiaryTransparent'],
    borderRadius: '50%',
    minWidth: 54,
    width: 54,
    height: 54,
    position: 'relative',
    marginRight: marginRight || 0,
    display: 'flex',
    textAlign: 'center',

    ['> div']: {
      width: 28,
      height: 28,
      display: 'block',
      margin: 'auto',

      ['> svg']: {
        display: 'block',
      },
    },
  })
)

import { ResponseType } from '@/models/ResponseType'
import { createSelector } from 'reselect'
import { UseQueryResult } from 'react-query'
import { SystemError } from '@/models/SystemError'
import { ApiStatus, useAppContext } from '@/core'
import { ProductDetailsModel, useProductDetailsQuery } from '@/api/products/productDetails'
import { AppContext } from '@/models/AppContext'

interface State {
  productDetails: UseQueryResult<
    ProductDetailsModel.ResponseData,
    ProductDetailsModel.ErrorResponse
  >
}

const selector = createSelector(
  (state: State) => state.productDetails.data,
  (state: State) => state.productDetails.status,
  (state: State) => state.productDetails.refetch,
  (
    productDetailsResponse,
    productDetailsStatus
  ): {
    error: SystemError<'NETWORK' | 'IS_EMPTY_PRODUCTS'> | null
    status: ApiStatus
    productDetails: ProductDetailsModel.ProductDetails
  } => {
    let productDetails: ProductDetailsModel.ProductDetails

    let hasNetworkError = false
    let status = ApiStatus.Idle

    if (productDetailsStatus === 'success' || productDetailsStatus === 'error') {
      status = ApiStatus.Fulfilled
    }

    if (productDetailsStatus === 'loading') {
      status = ApiStatus.Pending
    }

    if (productDetailsStatus === 'error') {
      hasNetworkError = true
    }

    if (productDetailsResponse && productDetailsResponse.type === ResponseType.ResolveWithData) {
      productDetails = productDetailsResponse.productDetails as ProductDetailsModel.ProductDetails
    }

    const error: SystemError<'NETWORK' | 'IS_EMPTY_PRODUCTS'> | null = hasNetworkError
      ? {
          type: 'NETWORK',
          message: 'При получении данных произошла ошибка',
        }
      : status === ApiStatus.Fulfilled && !productDetails
      ? {
          type: 'IS_EMPTY_PRODUCTS',
          message: 'Список продуктов пуст',
        }
      : null

    return {
      error: error,
      productDetails,
      status,
    }
  }
)

export function useDialogCreditDetailsState(
  accountNumber: string,
  productType: number,
  contractNumber: string
) {
  const context = useAppContext<AppContext>()

  const productDetails = useProductDetailsQuery({
    accountNumber,
    productType,
    contractNumber,
    apiVersion: context.config.apiVersion,
  })

  return selector({ productDetails })
}

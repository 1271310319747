import styled from '@emotion/styled'
import { Headline20, Headline24, Body14 } from '@platform-ui/typography'
import { IconWrapper } from '@/components/IconWrapper'

export const NextPaymentWrapper = styled.div(({ theme }) => ({
  margin: '24px 0',

  [`${theme.media.md}`]: {
    margin: '40px 0 48px',
  },
}))

export const NextPaymentTitleWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  cursor: 'pointer',
  margin: '0 16px 24px',

  [`${theme.media.md}`]: {
    margin: '0 32px 32px',
  },
}))

export const NextPaymentTitle = styled(Headline24)(() => ({
  fontSize: 20,
}))

export const DateWrapper = styled.div(({ theme }) => ({
  margin: '0 16px 24px',
  display: 'flex',
  alignItems: 'center',

  [`${theme.media.md}`]: {
    margin: '0 16px 32px 32px',
  },
}))

export const DateTitle = styled(Body14)(({ theme }) => ({
  fontSize: 12,
  color: theme.color['text/secondary'],
  marginBottom: 8,
}))

export const DateDesc = styled(Headline20)(() => ({
  fontSize: 16,
  marginRight: 35,
}))

export const DateIconWrapper = styled(IconWrapper)(() => ({
  width: 54,
  height: 54,
  marginRight: 16,

  ['& > div']: {
    width: 28,
    height: 28,
  },
}))

import { ResponseType } from '@/models/ResponseType'
import { RejectedWithSystemErrorResponse } from '@/models/RejectedWithSystemErrorResponse'
import { HttpClient } from '@/core'
import { AdapterModel } from './AdapterModel'
import { ticksToDate } from '@/utils/ticksToDate'
import { formatDate } from '@/utils/formatDate'

export const adapter: AdapterModel.Func = (data) => {
  const errorData = data as AdapterModel.ResponseDataError
  /**
   * Сервер всегда возвращает status=200 в заголовке
   * Реальный статус приходит в теле ответа
   * 401 или 500+ считаем rejected ответами с наличием критической ошибки
   */
  if (errorData?.StatusCode === 401 || errorData?.StatusCode >= 500) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors: errorData.ErrorDetails
        ? errorData.ErrorDetails.map(({ Error }) => ({
            type: Error,
            message: Error,
          }))
        : [],
    }
    throw HttpClient.createError(errorData?.StatusCode, exception)
  }

  try {
    const errorData = data as AdapterModel.ResponseDataError
    /**
     * Есть ошибки от сервера
     */
    if (errorData.Errors && errorData.Errors.length) {
      return {
        type: ResponseType.ResolveWithSystemError,
        systemErrors: errorData.ErrorDetails.map(({ Error }) => ({
          type: Error,
          message: Error,
        })),
      }
    }

    const successData = data as AdapterModel.ResponseDataSuccess

    return {
      type: ResponseType.ResolveWithData,
      paymentNum: successData.Result.PaymentNum,
      unPaidPaymentNum: successData.Result?.UnPaidPaymentNum,
      lastPaymentNum: successData.Result?.LastPaymentNum,
      debt: {
        outstandingDebtAmount: successData.Result.Debt.OutstandingDebtAmount,
        penaltyAmount: successData.Result.Debt.PenaltyAmount,
        debtCommissionAct: successData.Result.Debt?.DebtCommissionAct,
        totalDebtAmount: successData.Result.Debt.TotalDebtAmount,
      },
      detail: successData.Result.Detail.map((detail) => {
        const dueDate = ticksToDate(detail.DueDateTicks)

        return {
          instalmentNumber: detail.InstalmentNumber,
          dueDateTicks: detail.DueDateTicks,
          instalmentAmount: detail.InstalmentAmount,
          datePaidTicks: detail.DatePaidTicks,
          amountPaid: detail.AmountPaid,
          instalmentStatus: detail.InstalmentStatus,
          instalmentType: detail.InstalmentType,
          interestSum: detail?.InterestSum,
          debtMain: detail?.DebtMain,
          serviceAmount: detail.ServiceAmount,
          dueDateFormat: dueDate ? formatDate(dueDate, 'DD.MM.YYYY') : null,
        }
      }),
    }
  } catch (error) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors: [{ message: 'Ошибка при обработке полученных данных', type: 'ADAPTER_ERROR' }],
    }

    throw HttpClient.createError(500, exception)
  }
}

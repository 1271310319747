import styled from '@emotion/styled'
import { Body14 } from '@platform-ui/typography'

export const CreditDetalsItem = styled.div(() => ({
  marginBottom: 35,

  ':last-child': {
    marginBottom: 72,
  },
}))

export const CreditDetailsWrapper = styled.div(() => ({
  position: 'relative',
}))

export const CreditLastTransaction = styled.div(() => ({
  textAlign: 'end',
}))

export const CreditDetailSecondary = styled(Body14)(({ theme }) => ({
  color: theme.color['text/secondary'],
}))

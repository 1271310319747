import styled from '@emotion/styled'
import { Shimmer } from '@platform-ui/components/Shimmer'

export const BankDetailsShimmerCell = styled.div(({ theme }) => {
  return {
    width: '100%',
    padding: 16,
    position: 'relative',
    marginBottom: 40,
  }
})

export const BankDetailsShimmerTitle = styled(Shimmer)(() => {
  return {
    marginBottom: 10,
    borderRadius: 10,
    height: 12,
    width: '100%',
  }
})

export const BankDetailsShimmerDesc = styled(Shimmer)(() => {
  return {
    borderRadius: 10,
    height: 12,
    width: 85,
  }
})

export const BankDetailsShimmerButton = styled(Shimmer)(() => {
  return {
    marginBottom: 10,
    borderRadius: 10,
    height: 56,
    width: '100%',
  }
})

import { useQuery, UseQueryOptions } from 'react-query'
import { ApiManager, ApiManagerModel, HttpClient } from '@/core'
import { ApiState } from '@/models/ApiState'
import { ProductDetailsModel } from './ProductDetailsModel'
import { adapter } from './adapters/adapter'

export const productDetails: ProductDetailsModel.Func = async (props) => {
  const { path, control } = ApiManager.getState<ApiState, ApiManagerModel.ApiOption>(
    (state) => state.products.getProductDetails,
    {} as ApiManagerModel.ApiOption
  )
  const response = await HttpClient.post<
    ProductDetailsModel.ResponseData,
    void,
    ProductDetailsModel.RequestData,
    ProductDetailsModel.RequestHeaders,
    ProductDetailsModel.ErrorResponse
  >({
    url: path,
    headers: {
      _ver_: props.apiVersion,
      _os_: '4',
    },
    data: {
      AccountNumber: props.accountNumber,
      ProductType: props.productType,
      ContractNumber: props.contractNumber,
    },
    responseAdapter: adapter,
    control,
  })

  return response
}

function productDetailsKey(props: { accountNumber: string }) {
  return ['v1/api/Payment/GetProductDetails', props.accountNumber]
}

export function useProductDetailsQuery<
  T extends ProductDetailsModel.ResponseData = ProductDetailsModel.ResponseData
>(
  props: ProductDetailsModel.Props,
  options: Omit<
    UseQueryOptions<ProductDetailsModel.ResponseData, ProductDetailsModel.ErrorResponse, T>,
    'queryKey' | 'queryFn'
  > = {}
) {
  const key = productDetailsKey(props)
  const result = useQuery(key, async () => productDetails(props).then((res) => res.data), options)

  return result
}

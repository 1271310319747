import React from 'react'
import { DialogCreditPaymentMain } from './DialogCreditPaymentMain'
import { DialogCreditPaymentContent } from './DialogCreditPaymentContent'
import { ClientProductsModel } from '@/api/products/clientProducts'

export interface DialogPaymentDetailsProps {
  sumToPay: number
  earlyPaymentSum: number
  penaltySum: number
  contractStatus: ClientProductsModel.ContractStatus
  creditStatus: ClientProductsModel.CreditLoanGuiStatus
  earlyPaymentType: ClientProductsModel.EarlyPaymentType
  creditLoanGuiData: ClientProductsModel.CreditLoanGuiData
  isUncertainPaymentSum: boolean
  nextPaymentDateDisplayed: string
  payment: number
}

export const DialogPaymentDetails = ({
  sumToPay,
  nextPaymentDateDisplayed,
  earlyPaymentSum,
  earlyPaymentType,
  contractStatus,
  creditStatus,
  isUncertainPaymentSum,
  creditLoanGuiData,
  penaltySum,
  payment,
}: DialogPaymentDetailsProps) => {
  const { remainingDebt, debtAmount } = creditLoanGuiData
  const isOverdued: boolean = creditStatus === 5 || creditStatus === 21
  const isNextPaymentLessThanInFiveDays: boolean =
    creditStatus === 3 || creditStatus === 4 || creditStatus === 15 || creditStatus === 16
  const isPaymentOnToday: boolean =
    contractStatus === 5 || creditStatus === 4 || creditStatus === 13 || creditStatus === 16
  const isEnoughMoney: boolean =
    creditStatus === 6 || creditStatus === 7 || creditStatus === 9 || creditStatus === 10

  const deducted: number =
    earlyPaymentType === 1
      ? sumToPay + earlyPaymentSum
      : earlyPaymentType === 0
      ? earlyPaymentSum
      : sumToPay

  return (
    <>
      <div>
        <DialogCreditPaymentMain
          isOverdued={isOverdued}
          deducted={deducted}
          isNextPaymentLessThanInFiveDays={isNextPaymentLessThanInFiveDays}
          isPaymentOnToday={isPaymentOnToday}
          isEnoughMoney={isEnoughMoney}
          nextPaymentDateDisplayed={nextPaymentDateDisplayed}
          debtAmount={debtAmount}
          remainingDebt={remainingDebt}
          isUncertainPaymentSum={isUncertainPaymentSum}
        />
        <DialogCreditPaymentContent
          debtAmount={debtAmount}
          earlyPaymentSum={earlyPaymentSum}
          earlyPaymentType={earlyPaymentType}
          payment={payment}
          penaltySum={penaltySum}
          creditLoanGuiData={creditLoanGuiData}
          contractStatus={contractStatus}
          isUncertainPaymentSum={isUncertainPaymentSum}
        />
      </div>
    </>
  )
}

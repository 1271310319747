import React from 'react'
import {
  ShimmerHeader,
  ShimmerItemWrapper,
  ShimmerImg,
  ShimmerTextWrapper,
  ShimmerTextItem,
  ShimmerTitle,
  ShimmerDesc,
  ShimmerButton,
} from './styled'

export const HistoryShimmer = () => {
  const shimmerData = Array.from({ length: 3 }).map((_, i) => i)

  return (
    <>
      <ShimmerHeader />
      {shimmerData.map((number) => {
        return (
          <ShimmerItemWrapper key={number}>
            <ShimmerImg />

            <ShimmerTextWrapper>
              <ShimmerTextItem>
                <ShimmerTitle />
                <ShimmerTitle />
              </ShimmerTextItem>
              <ShimmerTextItem>
                <ShimmerDesc />
                <ShimmerDesc />
              </ShimmerTextItem>
            </ShimmerTextWrapper>
          </ShimmerItemWrapper>
        )
      })}
      <ShimmerButton />
    </>
  )
}

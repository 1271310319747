import styled from '@emotion/styled'
import { ButtonBase } from '@platform-ui/components/ButtonBase'
import { Button } from '@platform-ui/components/Button'
import { Headline24 } from '@platform-ui/typography'

export const HistoryWrapper = styled.div<{ withBg?: boolean }>(({ theme, withBg }) => ({
  margin: '16px 0',
  padding: 16,
  background: withBg && '#F5F5F7',
  borderRadius: 20,

  [`${theme.media.md}`]: {
    margin: '40px 0',
    padding: 32,
  },
}))

export const HistoryTitleWrapper = styled(ButtonBase)(({ theme }) => ({
  marginBottom: 24,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  cursor: 'pointer',
  background: 'inherit',
  width: '100%',
  padding: '2px 0',

  [`${theme.media.md}`]: {
    marginBottom: 32,
  },
}))

export const ErrorTextWrapper = styled.div(({ theme }) => ({
  textAlign: 'center',
  paddingBottom: 8,

  [`${theme.media.md}`]: {
    paddingBottom: 0,
  },
}))

export const ErrorButton = styled(Button)(({ theme }) => ({
  marginTop: 4,

  [`${theme.media.md}`]: {
    marginTop: 12,
  },
}))

export const DialogButton = styled.div(({ theme }) => ({
  position: 'fixed',
  width: '100%',
  right: 0,
  left: 0,
  bottom: 0,
  padding: '32px 16px 50px',
  background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 23.19%)',
  borderRadius: 24,
  zIndex: 1,

  [`${theme.media.md}`]: {
    padding: 32,
    bottom: 18,
  },
}))

export const ErrorTitle = styled(Headline24)(({ theme }) => ({
  fontSize: 20,
  marginBottom: 32,

  [`${theme.media.md}`]: {
    lineHeight: '32px',
  },
}))

export const HistoryItemsWrapper = styled.div(() => ({
  paddingBottom: 50,
}))

import React from 'react'
import { Button } from '@platform-ui/components/Button'
import { formatDate } from '@/utils/formatDate'
import { StyledWrapper, Headline, MainContent } from './styled'

interface SoldOutLoanInfoProps {
  soldOutDate: string
  ownerId: number
  soldOutCreditDescription?: string
  skbDetailsButtonUrl?: string
}

export const SoldOutLoanInfo = ({
  soldOutDate,
  ownerId,
  soldOutCreditDescription,
  skbDetailsButtonUrl,
}): SoldOutLoanInfoProps => {
  return (
    <>
      <StyledWrapper>
        <Headline>Важная информация!</Headline>
        <MainContent>
          {soldOutCreditDescription && (
            <div
              dangerouslySetInnerHTML={{
                __html:
                  `${soldOutDate ? formatDate(new Date(soldOutDate), 'DD.MM.YY') + ' ' : ''}` +
                  soldOutCreditDescription,
              }}
            />
          )}
        </MainContent>

        {skbDetailsButtonUrl && ownerId === 966 && (
          <Button type="link" isFullWidth size="m" onClick={() => window.open(skbDetailsButtonUrl)}>
            Подробнее
          </Button>
        )}
      </StyledWrapper>
    </>
  )
}

import React from 'react'
import { Button } from '@platform-ui/components/Button'
import {
  WidgetWrapper,
  WidgetWrapperInner,
  WidgetContent,
  WidgetTitle,
  WidgetDesc,
  WidgetIconButton,
} from './styled'
import { Currency } from '@/components/Currency'
import { FeatureIcon } from '@platform-ui/icons/FeatureIcon'

export interface NextPaymentWidgetProps {
  deductedPayment: number
  accountBalance: number
  remainingDebt?: number
  isRepaymentCompleted: boolean
  buttonClick: () => void
}

export const NextPaymentWidget = ({
  deductedPayment,
  accountBalance,
  remainingDebt,
  isRepaymentCompleted,
  buttonClick,
}: NextPaymentWidgetProps) => {
  return (
    <WidgetWrapper>
      <WidgetWrapperInner>
        <WidgetContent isRepaymentCompleted={isRepaymentCompleted}>
          <WidgetDesc>Будет списано</WidgetDesc>
          <WidgetTitle color="text/main">
            <Currency amount={deductedPayment} isShowPrecisionWithNullCents />
          </WidgetTitle>
        </WidgetContent>

        <WidgetContent order={1} isRepaymentCompleted={isRepaymentCompleted}>
          <WidgetDesc>Баланс счёта</WidgetDesc>
          <WidgetTitle color="text/main">
            <Currency amount={accountBalance} isShowPrecisionWithNullCents />
          </WidgetTitle>
        </WidgetContent>

        <WidgetContent isRepaymentCompleted={isRepaymentCompleted}>
          {!isRepaymentCompleted ? (
            <>
              <WidgetDesc>Осталось доплатить</WidgetDesc>
              <WidgetTitle color="text/main">
                <Currency amount={remainingDebt} isShowPrecisionWithNullCents />
              </WidgetTitle>
            </>
          ) : (
            <>
              <WidgetIconButton backgroundColor="icons/shape">
                <FeatureIcon color="icons/colors/success" size="s16" />
              </WidgetIconButton>
            </>
          )}
        </WidgetContent>
      </WidgetWrapperInner>

      <Button isFullWidth size="m" onClick={buttonClick}>
        {!isRepaymentCompleted ? (
          <>
            Пополнить на <Currency amount={remainingDebt} />
          </>
        ) : (
          'Пополнить счёт на будущее'
        )}
      </Button>
    </WidgetWrapper>
  )
}

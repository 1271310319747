import { ResponseType } from '@/models/ResponseType'
import { createSelector } from 'reselect'
import { UseQueryResult } from 'react-query'
import { SystemError } from '@/models/SystemError'
import { ClientProductsModel, useClientProductsQuery } from '@/api/products/clientProducts'
import { ApiStatus, useAppContext } from '@/core'
import { Rules } from '@/models/Rules'
import { AppContext } from '@/models/AppContext'

interface State {
  creditLoans: UseQueryResult<ClientProductsModel.ResponseData, ClientProductsModel.ErrorResponse>
  productId: string
}

const selector = createSelector(
  (state: State) => state.creditLoans.data,
  (state: State) => state.creditLoans.status,
  (state: State) => state.creditLoans.refetch,
  (state: State) => state.productId,
  (
    creditLoansResponse,
    creditLoansStatus,
    creditLoansRefetch,
    productId
  ): {
    error: SystemError<'NETWORK' | 'IS_EMPTY_PRODUCTS'> | null
    status: ApiStatus
    retry: () => void
    productById: ClientProductsModel.CreditLoan
    productNotFound: boolean
  } => {
    const retryFns: (() => void)[] = []

    let products: ClientProductsModel.CreditLoan[] = []

    let hasNetworkError = false
    let status = ApiStatus.Idle

    if (creditLoansStatus === 'success' || creditLoansStatus === 'error') {
      status = ApiStatus.Fulfilled
    }

    if (creditLoansStatus === 'loading') {
      status = ApiStatus.Pending
    }

    if (creditLoansStatus === 'error') {
      hasNetworkError = true
    }

    if (creditLoansStatus === 'error') {
      retryFns.push(creditLoansRefetch)
    }

    if (
      creditLoansResponse &&
      (creditLoansResponse.type === ResponseType.ResolveWithData ||
        creditLoansResponse.type === ResponseType.ResolveWithDataAndSystemError)
    ) {
      products = products.concat(
        ...(creditLoansResponse.products.filter(
          (product) =>
            product.categoryType === ClientProductsModel.CategoryType.CreditLoan &&
            product.status !== ClientProductsModel.CreditLoanGuiStatus.NotSet
        ) as ClientProductsModel.CreditLoan[])
      )
    }

    const error: SystemError<'NETWORK' | 'IS_EMPTY_PRODUCTS'> | null = hasNetworkError
      ? {
          type: 'NETWORK',
          message: 'При получении данных произошла ошибка',
        }
      : status === ApiStatus.Fulfilled && !products.length
      ? {
          type: 'IS_EMPTY_PRODUCTS',
          message: 'Список продуктов пуст',
        }
      : null

    const productById = products.find(({ id }) => id === productId)

    return {
      error: error,
      productById: productById,
      productNotFound: !productById,
      status,
      retry: () => {
        retryFns.forEach((item) => {
          item()
        })
      },
    }
  }
)

export function useGetCreditProductById(productId: string) {
  const context = useAppContext<AppContext>()

  const creditLoans = useClientProductsQuery({
    apiVersion: context.config.apiVersion,
  })

  return selector({ creditLoans, productId })
}

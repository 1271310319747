import React, { useState } from 'react'
import { Headline24, Body14 } from '@platform-ui/typography'
import { Button } from '@platform-ui/components/Button'
import { ChevronRightIcon } from '@platform-ui/icons/ChevronRightIcon'
import { HistoryItem } from '../../components/HistoryItem'
import {
  HistoryWrapper,
  HistoryTitleWrapper,
  ErrorTextWrapper,
  ErrorButton,
  ErrorTitle,
  HistoryItemsWrapper,
} from './styled'
import { useGetReceivedPaymentsQuery } from '@/api/payment/getReceivedPayments'
import { ResponseType } from '@/models/ResponseType'
import { HistoryShimmer } from '../../components/HistoryShimmer'
import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import { observer } from 'mobx-react-lite'
import { BaseModal } from '@platform-ui/components'

export interface HistoryProps {
  contractNumber: string
}

export const History = observer(({ contractNumber }: HistoryProps) => {
  const { config } = useAppContext<AppContext>()
  const [isOpenModal, setIsOpenModal] = useState(false)
  const { data, refetch, isError, isLoading } = useGetReceivedPaymentsQuery(
    { contractNumber, apiVersion: config.apiVersion },
    {
      retry: 3,
    }
  )

  if (isLoading) {
    return (
      <HistoryWrapper withBg>
        <HistoryShimmer />
      </HistoryWrapper>
    )
  }

  if (isError) {
    return (
      <HistoryWrapper withBg>
        <ErrorTitle>Поступившие платежи</ErrorTitle>
        <ErrorTextWrapper>
          <Body14>Не удалось загрузить историю платежей</Body14>
          <ErrorButton onClick={() => refetch()} sizeSm={'l'} view="ghost">
            Повторить
          </ErrorButton>
        </ErrorTextWrapper>
      </HistoryWrapper>
    )
  }

  const onOpenModal = () => setIsOpenModal(true)

  const onCloseModal = () => {
    setIsOpenModal(false)
  }

  if (data?.type === ResponseType.ResolveWithData) {
    if (!data.payments.length) {
      return (
        <HistoryWrapper withBg>
          <ErrorTitle>Поступившие платежи</ErrorTitle>
          <ErrorTextWrapper>
            <Body14>Операций не найдено</Body14>
          </ErrorTextWrapper>
        </HistoryWrapper>
      )
    }

    return (
      <HistoryWrapper>
        <HistoryTitleWrapper onClick={onOpenModal}>
          <Headline24>Поступившие платежи</Headline24>
          <ChevronRightIcon size="s12" color="text/main" />
        </HistoryTitleWrapper>

        {data.payments.slice(0, 3).map(({ amount, date }, number) => (
          <HistoryItem key={number} amount={amount} date={date} />
        ))}

        <Button view={'secondary'} isFullWidth onClick={onOpenModal}>
          Смотреть все
        </Button>

        <BaseModal
          isOpen={isOpenModal}
          onClose={onCloseModal}
          title="Поступившие платежи"
          primaryBtnTitle="Закрыть"
          primaryBtnClick={onCloseModal}
        >
          <HistoryItemsWrapper>
            {data.payments.map(({ amount, date }, number) => (
              <HistoryItem key={number} amount={amount} date={date} />
            ))}
          </HistoryItemsWrapper>
        </BaseModal>
      </HistoryWrapper>
    )
  }

  return null
})

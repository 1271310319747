import React, { ReactNode } from 'react'
import { DetailName, DetalItem } from './styled'

export interface DetailsItemProps {
  title: string
  children?: ReactNode
}

export const DetailsItem = ({ title, children }: DetailsItemProps) => {
  return (
    <>
      <DetalItem>
        <DetailName>{title}</DetailName>
        {children}
      </DetalItem>
    </>
  )
}

import React from 'react'
import { BankDetailsShimmerCell, BankDetailsShimmerTitle, BankDetailsShimmerDesc } from './styled'

const shimmerData = Array.from({ length: 5 }).map((_, i) => i)

export const DialogCreditDetailsShimmer = () => {
  return (
    <div>
      {shimmerData.map((number) => {
        return (
          <BankDetailsShimmerCell key={number}>
            <BankDetailsShimmerTitle />
            <BankDetailsShimmerDesc />
          </BankDetailsShimmerCell>
        )
      })}
    </div>
  )
}

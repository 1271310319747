import { ApiManager, ApiManagerModel, HttpClient } from '@/core'
import { ApiState } from '@/models/ApiState'
import { MutateOptions, useMutation } from 'react-query'
import { adapter } from './adapters/adapter'
import { GetInstalmentScheduleModel } from './GetInstalmentScheduleModel'

export const getInstalmentSchedule: GetInstalmentScheduleModel.Func = async (props) => {
  const { path, control } = ApiManager.getState<ApiState, ApiManagerModel.ApiOption>(
    (state) =>
      props.isEarly
        ? state.payment.getEarlyRepaymentInstalmentSchedule
        : state.payment.getInstalmentSchedule,
    {} as ApiManagerModel.ApiOption
  )

  const response = await HttpClient.post<
    GetInstalmentScheduleModel.ResponseData,
    void,
    GetInstalmentScheduleModel.RequestData,
    GetInstalmentScheduleModel.RequestHeaders,
    GetInstalmentScheduleModel.ErrorResponse
  >({
    url: path,
    data: {
      contractNumber: props.contractNumber,
    },
    headers: {
      _ver_: props.apiVersion,
      _os_: '4',
    },
    responseAdapter: adapter,
    options: {
      auth: true,
    },
    control,
  })

  return response
}

export function useGetInstalmentSchedule(
  options?: MutateOptions<
    GetInstalmentScheduleModel.Response,
    GetInstalmentScheduleModel.ErrorResponse,
    GetInstalmentScheduleModel.Props
  >
) {
  return useMutation(getInstalmentSchedule, options)
}

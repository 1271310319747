import React, { useState } from 'react'
import {
  NextPaymentWrapper,
  NextPaymentTitleWrapper,
  NextPaymentTitle,
  DateWrapper,
  DateTitle,
  DateDesc,
  DateIconWrapper,
} from './styled'
import { NextPaymentWidget } from '../../components/NextPaymentWidget'
import { ClientProductsModel } from '@/api/products/clientProducts'
import { formatDate } from '@/utils/formatDate'
import { AttentionIcon } from '@platform-ui/icons/AttentionIcon'
import { pluralize } from '@/utils/pluralize'
import { getStatusInfo } from '../../utils'
import { DialogPaymentDetails } from '../DialogPaymentDetails'
import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import { observer } from 'mobx-react-lite'
import { ChevronRightIcon } from '@platform-ui/icons/ChevronRightIcon'
import { BaseModal } from '@platform-ui/components'
import { Currency } from '@/components/Currency'

export enum StatusText {
  PaymentBefore = 'paymentBefore',
  PaymentToday = 'paymentToday',
  PaymentOverdue = 'paymentOverdue',
}

const getBgColor = (paymentStatus: string): string => {
  if (paymentStatus === StatusText.PaymentToday) {
    return 'surface/attention'
  }
  if (paymentStatus === StatusText.PaymentOverdue) {
    return 'icons/colors/error'
  }
  return ''
}

const toCurrentFormatDate = (date: string): string => {
  const parse = new Date(Date.parse(date))
  return formatDate(parse, 'D MMMM')
}

export interface NextPaymentProps {
  productId: string
  sumToPay: number
  earlyPaymentSum: number
  penaltySum: number
  accountBalance: number
  earlyPaymentType: ClientProductsModel.EarlyPaymentType
  creditLoanGuiData: ClientProductsModel.CreditLoanGuiData
  contractStatus: ClientProductsModel.ContractStatus
  nextPaymentDateDisplayed: string
  isUncertainPaymentSum: boolean
  payment: number
}

export enum DialogId {
  PaymentDetails = 'PaymentDetails',
  PaymentSchedule = 'PaymentSchedule',
  Pay = 'pay',
}

const useDeductedPayment = (
  earlyPaymentType: number,
  sumToPay: number,
  earlyPaymentSum: number
): number => {
  switch (earlyPaymentType) {
    case 0:
      return earlyPaymentSum
    case 1:
      return sumToPay + earlyPaymentSum
    default:
      return sumToPay
  }
}

export const NextPayment = observer(
  ({
    nextPaymentDateDisplayed,
    creditLoanGuiData,
    sumToPay,
    contractStatus,
    earlyPaymentSum,
    earlyPaymentType,
    isUncertainPaymentSum,
    penaltySum,
    accountBalance,
    productId,
    payment,
  }: NextPaymentProps) => {
    const [isOpenModal, setIsOpenModal] = useState(false)
    const [activeDialogId, setActiveDialogId] = useState('')
    const { eventBus } = useAppContext<AppContext>()
    const { debtDays, remainingDebt, daysLeft } = creditLoanGuiData
    const status: ClientProductsModel.CreditLoanGuiStatus = creditLoanGuiData.creditLoanGuiStatus

    const isRepaymentCompleted =
      Boolean(status === ClientProductsModel.CreditLoanGuiStatus.PaymentInAdvance) ||
      Boolean(status === ClientProductsModel.CreditLoanGuiStatus.EarlyRepayment) ||
      Boolean(status === ClientProductsModel.CreditLoanGuiStatus.PartialEarlyRepayment) ||
      Boolean(status === ClientProductsModel.CreditLoanGuiStatus.AllPrepayment)

    const nextPaymentDateFormat = toCurrentFormatDate(nextPaymentDateDisplayed)
    const overdueDaysFormat = `${debtDays} ${pluralize(debtDays, ['день', 'дня', 'дней'])}`
    const daysLeftFormat = `${daysLeft} ${pluralize(daysLeft, ['день', 'дня', 'дней'])}`
    const isEnoughMoney: boolean = status === 6 || status === 7 || status === 9 || status === 10

    const { text, paymentStatus } = getStatusInfo(
      status,
      nextPaymentDateFormat,
      overdueDaysFormat,
      debtDays,
      daysLeftFormat,
      daysLeft
    )
    const bgColorForIcon = getBgColor(paymentStatus)
    const deductedPayment = useDeductedPayment(earlyPaymentType, sumToPay, earlyPaymentSum)

    const onOpenModal = (id: DialogId) => {
      if (id === DialogId.Pay) {
        eventBus.payListModal.open.emit({ id: productId })
        onCloseModal()
        return
      }

      setIsOpenModal(true)
      setActiveDialogId(id)
    }

    const onCloseModal = (): void => {
      setIsOpenModal(false)
      setActiveDialogId('')
    }

    return (
      <>
        <NextPaymentWrapper>
          <NextPaymentTitleWrapper onClick={() => onOpenModal(DialogId.PaymentDetails)}>
            <NextPaymentTitle color="text/main">Следующий платёж</NextPaymentTitle>
            <ChevronRightIcon size="s12" color="text/main" />
          </NextPaymentTitleWrapper>

          <DateWrapper>
            {(paymentStatus === StatusText.PaymentToday ||
              paymentStatus === StatusText.PaymentOverdue) && (
              <DateIconWrapper backgroundColor={bgColorForIcon}>
                <AttentionIcon />
              </DateIconWrapper>
            )}
            <div>
              {paymentStatus === StatusText.PaymentBefore && <DateTitle>Дата платежа</DateTitle>}
              <DateDesc color="text/main">{text}</DateDesc>
            </div>
          </DateWrapper>

          <NextPaymentWidget
            deductedPayment={deductedPayment}
            accountBalance={accountBalance}
            remainingDebt={!isRepaymentCompleted ? remainingDebt : null}
            isRepaymentCompleted={isRepaymentCompleted}
            buttonClick={() => onOpenModal(DialogId.Pay)}
          />
          <BaseModal
            isOpen={isOpenModal && activeDialogId === DialogId.PaymentDetails}
            onClose={onCloseModal}
            title="Состав платежа"
            primaryBtnTitle={
              isUncertainPaymentSum ? (
                'Пополнить'
              ) : isEnoughMoney ? (
                `Пополнить счёт на будущее`
              ) : (
                <>
                  Пополнить на <Currency amount={remainingDebt} isShowPrecisionWithNullCents />
                </>
              )
            }
            primaryBtnClick={() => onOpenModal(DialogId.Pay)}
          >
            <DialogPaymentDetails
              sumToPay={sumToPay}
              payment={payment}
              nextPaymentDateDisplayed={nextPaymentDateDisplayed}
              isUncertainPaymentSum={isUncertainPaymentSum}
              contractStatus={contractStatus}
              creditStatus={status}
              creditLoanGuiData={creditLoanGuiData}
              earlyPaymentSum={earlyPaymentSum}
              earlyPaymentType={earlyPaymentType}
              penaltySum={penaltySum}
            />
          </BaseModal>
        </NextPaymentWrapper>
      </>
    )
  }
)

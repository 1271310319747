import React from 'react'
import styled from '@emotion/styled'
import { Headline16, Body14 } from '@platform-ui/typography'
import { Container } from '@platform-ui/components/Container'
import { IconWrapper } from '@/components/IconWrapper'

export const HistoryItemContainer = styled(Container)(({ theme }) => {
  return {
    marginBottom: 24,

    [`${theme.media.md}`]: {
      marginBottom: 32,
    },
  }
})

export const HistoryItemIcon = styled(IconWrapper)(({ theme }) => {
  return {
    background: theme.color['icons/PFM/twentyeight'],
    marginRight: 12,
  }
})

export const HistoryItemDesc = styled(Body14)(({ theme }) => {
  return {
    marginTop: 4,
    color: theme.color['text/secondary'],
  }
})

export const HistoryItemAmount = styled(Headline16)(({ theme }) => {
  return {
    color: theme.color['text/success'],
  }
})

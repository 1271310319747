import React from 'react'
import { DialogCreditDetailsSuccess } from './DialogCreditDetailsSuccess'
import { useDialogCreditDetailsState } from '../../selectors/useDialogCreditDetailsState'
import { DialogCreditDetailsShimmer } from '../../components/DialogCreditDetailsShimmer'
import { ErrorDataLoadingModal } from '@/components/ErrorDataLoadingModal/ErrorDataLoadingModal'
import { ApiStatus } from '@/core'
import { ClientProductsModel } from '@/api/products/clientProducts'
import { BaseModal } from '@platform-ui/components'

export interface DialogBankDetailsProps {
  onClose: () => void
  accountNumber: string
  isOpen?: boolean
  title?: string
  product: Pick<
    ClientProductsModel.CreditLoan,
    | 'contractNumber'
    | 'productName'
    | 'productTypeNumber'
    | 'creditAmount'
    | 'accountNumber'
    | 'dateSign'
    | 'smsPackService'
    | 'accountBalance'
  >
  isSoldOutCredit?: boolean
}

export const DialogCreditDetails = ({
  onClose,
  accountNumber,
  product,
  isOpen,
  title,
  isSoldOutCredit,
}: DialogBankDetailsProps) => {
  const { productDetails, status, error } = useDialogCreditDetailsState(
    accountNumber,
    product.productTypeNumber,
    product.contractNumber
  )

  const isLoading = status === ApiStatus.Pending

  if (isLoading || error || productDetails) {
    return (
      <BaseModal
        isOpen={isOpen}
        onClose={onClose}
        title={title}
        primaryBtnTitle="Закрыть"
        primaryBtnClick={onClose}
        isPending={isLoading}
      >
        {status === ApiStatus.Pending && <DialogCreditDetailsShimmer />}
        {error && (
          <ErrorDataLoadingModal
            title={<>Информация о&nbsp;кредите не&nbsp;найдена</>}
            description="Возникли технические проблемы, повторите попытку позже"
          />
        )}
        {productDetails && (
          <DialogCreditDetailsSuccess
            product={product}
            insurance={productDetails.insurance}
            insuranceName={productDetails?.insuranceName}
            finProtection={productDetails.finProtection}
            postingDate={productDetails.postingDate}
            transactionAmount={productDetails.transactionAmount}
            isSoldOutCredit={isSoldOutCredit}
          />
        )}
      </BaseModal>
    )
  }

  return null
}

import { ClientProductsModel } from '@/api/products/clientProducts'
import { StatusText } from '../containers/NextPayment'

/**
 * В зависимости от CreditLoanGuiStatus получить текст и общий статус
 *
 * @param {string} status
 * @param {string} nextPaymentDateFormat
 * @param {string} overdueDaysFormat
 * @param {number} debtDays
 * @param {string} daysLeftFormat
 * @param {number} daysLeft
 * @returns {{ text:string, paymentStatus:string }}
 */
export const getStatusInfo = (
  status: ClientProductsModel.CreditLoanGuiStatus,
  nextPaymentDateFormat: string,
  overdueDaysFormat: string,
  debtDays: number,
  daysLeftFormat: string,
  daysLeft: number
): {
  text: string
  paymentStatus: string
} => {
  if (
    status === ClientProductsModel.CreditLoanGuiStatus.PayOnlyEarlypaymentPaymentDay ||
    status === ClientProductsModel.CreditLoanGuiStatus.BothPaymentPaymentDay ||
    status === ClientProductsModel.CreditLoanGuiStatus.PaymentDay
  ) {
    return {
      text: 'Платёж сегодня',
      paymentStatus: StatusText.PaymentToday,
    }
  }
  if (
    status === ClientProductsModel.CreditLoanGuiStatus.OverduePayment ||
    status === ClientProductsModel.CreditLoanGuiStatus.OnlyPenalty
  ) {
    return {
      text: !debtDays ? 'Просрочен' : `Платёж просрочен на ${overdueDaysFormat}`,
      paymentStatus: StatusText.PaymentOverdue,
    }
  }

  const nextPaymentText = daysLeft === 0 ? 'сегодня' : `через ${daysLeftFormat}`

  return {
    text: `${nextPaymentDateFormat}, ${nextPaymentText}`,
    paymentStatus: StatusText.PaymentBefore,
  }
}

import React, { useState } from 'react'
import { Accordion } from '@platform-ui/components/Accordion'
import { ChevronDownIcon } from '@platform-ui/icons/ChevronDownIcon'
import { useCalcAmount } from './useCalcAmount'
import { GetInstalmentScheduleModel } from '@/api/payment/getInstalmentSchedule'
import { Currency, CurrencyProps } from '@/components/Currency'
import {
  RequirementWrapper,
  ItemTitle,
  MainTitle,
  ScheduleArrow,
  SumText,
  TotalDebtWrapper,
  MainSum,
  RightContent,
  AppealText,
  ListItem,
  BottomContent,
} from './styled'

interface RequirementPaymentProps {
  detail: GetInstalmentScheduleModel.Detail
  debt: GetInstalmentScheduleModel.Debt
}

export const RequirementPayment: React.FC<RequirementPaymentProps> = ({ detail, debt }) => {
  const { totalAmount, amounts } = useCalcAmount(detail, debt)

  const contentList = [
    {
      title: 'Основной долг',
      secondText: 'Просроченная задолженность',
      totalSum: amounts.main,
    },
    {
      title: 'Проценты',
      secondText: 'Текущая сумма процентов по кредиту',
      totalSum: amounts.interest,
    },
    {
      title: 'Госпошлина',
      secondText: 'Затраты банка при обращении в суд',
      totalSum: amounts.duty,
    },
    {
      title: 'Убытки',
      secondText: 'Неполученный доход банка из-за нарушения договора',
      totalSum: amounts.losses,
    },
    {
      title: 'Штрафы',
      secondText: 'Пени 0,1% от просроченного долга в день',
      totalSum: amounts.fines,
    },
    {
      title: 'Прочие платежи платежи',
      secondText: 'Комиссии и иные платежи',
      totalSum: amounts.other,
    },
  ]

  const [isOpen, setIsOpen] = useState(false)

  return (
    <RequirementWrapper>
      <MainTitle>
        Требуется полное погашение <br /> задолженности
      </MainTitle>
      <TotalDebtWrapper onClick={() => setIsOpen(!isOpen)}>
        <SumText>Сумма задолженности</SumText>
        <RightContent>
          <MainSum>
            <Currency amount={totalAmount} isShowPrecisionWithNullCents={true} />
          </MainSum>
          <ScheduleArrow isOpen={isOpen}>
            <ChevronDownIcon />
          </ScheduleArrow>
        </RightContent>
      </TotalDebtWrapper>

      <Accordion isOpen={isOpen}>
        {contentList.map(({ title, secondText, totalSum }) => {
          return (
            <ListItem key={title}>
              <ItemTitle>{title}</ItemTitle>
              <BottomContent>
                <p>{secondText}</p>
                <p>
                  <Currency amount={totalSum} isShowPrecisionWithNullCents={true} />
                </p>
              </BottomContent>
            </ListItem>
          )
        })}
        <AppealText>
          <p>
            Если вы не можете погасить задолженность целиком обратитесь по бесплатному номеру
            телефона
          </p>
          <a href="tel:88007008977">8 (800) 700-89-77</a>
        </AppealText>
      </Accordion>
    </RequirementWrapper>
  )
}

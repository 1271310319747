import { ApiManager, ApiManagerModel, HttpClient } from '@/core'
import { ApiState } from '@/models/ApiState'
import { useQuery, UseQueryOptions } from 'react-query'
import { adapter } from './adapters/adapter'
import { GetReceivedPaymentsModel } from './GetReceivedPaymentsModel'

export const getReceivedPayments: GetReceivedPaymentsModel.Func = async (props) => {
  const { path, control } = ApiManager.getState<ApiState, ApiManagerModel.ApiOption>(
    (state) => state.payment.getReceivedPayments,
    {} as ApiManagerModel.ApiOption
  )

  return await HttpClient.post<
    GetReceivedPaymentsModel.ResponseData,
    void,
    GetReceivedPaymentsModel.RequestData,
    GetReceivedPaymentsModel.RequestHeaders,
    GetReceivedPaymentsModel.ErrorResponse
  >({
    url: path,
    headers: {
      _ver_: props.apiVersion,
      _os_: '4',
    },
    data: {
      contractNumber: props.contractNumber,
    },
    responseAdapter: adapter,
    options: {
      auth: true,
    },
    control,
  })
}

export function getReceivedPaymentsQueryKey(props: { contractNumber: string }) {
  return ['/api/Payment/GetReceivedPayments', props.contractNumber]
}

export function useGetReceivedPaymentsQuery<T = GetReceivedPaymentsModel.ResponseData>(
  props: GetReceivedPaymentsModel.Props,
  options?: Omit<
    UseQueryOptions<
      GetReceivedPaymentsModel.ResponseData,
      GetReceivedPaymentsModel.ErrorResponse,
      T
    >,
    'queryKey' | 'queryFn'
  >
) {
  const key = getReceivedPaymentsQueryKey(props)
  return useQuery(key, async () => getReceivedPayments(props).then((res) => res.data), options)
}

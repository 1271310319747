import React from 'react'
import { Currency } from '@/components/Currency'
import {
  DialogSubTitle,
  DialogTitle,
  DialogNextPaymentWrapper,
  CurrenceWrapper,
  PaymentItem,
  PaymentInfoWrapper,
  IconWrapper,
  InfoMessageWrapper,
  DialogMain,
  DateInfo,
} from './styled'
import { formatDate } from '@/utils/formatDate'
import { Body16 } from '@platform-ui/typography'
import { TimeIcon } from '@platform-ui/icons/TimeIcon'
import { AttentionIcon, FeatureIcon } from '@platform-ui/icons'

export interface DialogPaymentStandardScreenProps {
  isUncertainPaymentSum: boolean
  isOverdued: boolean
  isPaymentOnToday: boolean
  isEnoughMoney: boolean
  deducted: number
  debtAmount: number
  remainingDebt: number
  isNextPaymentLessThanInFiveDays: boolean
  nextPaymentDateDisplayed: string
}

export const DialogCreditPaymentMain = ({
  isOverdued,
  deducted,
  isNextPaymentLessThanInFiveDays,
  isPaymentOnToday,
  isEnoughMoney,
  debtAmount,
  remainingDebt,
  nextPaymentDateDisplayed,
  isUncertainPaymentSum,
}: DialogPaymentStandardScreenProps) => {
  const bgColor = isOverdued
    ? 'surface/lightError'
    : isNextPaymentLessThanInFiveDays
    ? 'surface/lightAttention'
    : 'surface/tertiary'

  return (
    <>
      {(isUncertainPaymentSum || isOverdued) && (
        <InfoMessageWrapper>
          <IconWrapper bgColor={isUncertainPaymentSum ? 'primary' : 'error'} marginRight={16}>
            {isUncertainPaymentSum && <TimeIcon color="icons/shape" />}
            {!isUncertainPaymentSum && <AttentionIcon color="icons/shape" />}
          </IconWrapper>
          <Body16>
            {isUncertainPaymentSum ? (
              'Скоро рассчитаем сумму'
            ) : (
              <>
                Погасите сегодня просроченную задолженность{' '}
                <Currency amount={debtAmount} isShowPrecisionWithNullCents />
              </>
            )}
          </Body16>
        </InfoMessageWrapper>
      )}

      <DialogNextPaymentWrapper bgColor={bgColor}>
        <DialogMain>
          <DialogTitle>Предстоящий платёж</DialogTitle>

          {!isUncertainPaymentSum && (
            <PaymentInfoWrapper>
              <PaymentItem isEnoughMoney={isEnoughMoney}>
                <DialogSubTitle>Будет списано</DialogSubTitle>
                <CurrenceWrapper>
                  <Currency amount={deducted} isShowPrecisionWithNullCents />
                </CurrenceWrapper>
              </PaymentItem>
              {!isEnoughMoney && (
                <PaymentItem withLeftSpacing>
                  <DialogSubTitle>Осталось доплатить</DialogSubTitle>
                  <CurrenceWrapper>
                    <Currency amount={remainingDebt} isShowPrecisionWithNullCents />
                  </CurrenceWrapper>
                </PaymentItem>
              )}
            </PaymentInfoWrapper>
          )}

          <PaymentInfoWrapper>
            <PaymentItem>
              <DialogSubTitle>Дата списания</DialogSubTitle>
              <DateInfo>
                {isPaymentOnToday
                  ? 'Сегодня'
                  : formatDate(new Date(nextPaymentDateDisplayed), 'DD.MM.YYYY')}
              </DateInfo>
            </PaymentItem>
          </PaymentInfoWrapper>
        </DialogMain>
        {isEnoughMoney && !isUncertainPaymentSum && (
          <IconWrapper>
            <FeatureIcon color="icons/colors/success" />
          </IconWrapper>
        )}
      </DialogNextPaymentWrapper>
    </>
  )
}

import React from 'react'
import { Headline16 } from '@platform-ui/typography'
import { Row } from '@platform-ui/components/Row'
import { Col } from '@platform-ui/components/Col'
import { Currency } from '@/components/Currency'
import { PopolnenieIcon } from '@platform-ui/icons/PopolnenieIcon'
import { HistoryItemContainer, HistoryItemIcon, HistoryItemDesc, HistoryItemAmount } from './styled'
import { formatDate } from '@/utils/formatDate'

export interface HistoryItemProps {
  amount: number
  date: string
}

const toCurrentFormatDate = (date: string) => {
  if (isNaN(Date.parse(date))) {
    return null
  }
  const parse = new Date(Date.parse(date))

  const paymentDateFormat = formatDate(parse, 'DD.MM.YYYY')
  const todayDate = formatDate(new Date(), 'DD.MM.YYYY')

  if (todayDate === paymentDateFormat) {
    return formatDate(parse, 'Сегодня, HH:mm')
  }

  return formatDate(parse, 'DD.MM.YYYY, HH:mm')
}

export const HistoryItem = ({ amount, date }: HistoryItemProps) => {
  const formattedDate = toCurrentFormatDate(date)

  return (
    <HistoryItemContainer>
      <Row>
        <Col displaySm="flex" alignItemsSm="center">
          <HistoryItemIcon>
            <PopolnenieIcon />
          </HistoryItemIcon>
          <div>
            <Headline16>Пополнение</Headline16>
            <HistoryItemDesc>Поступление</HistoryItemDesc>
          </div>
        </Col>
        <Col displaySm="flex" flexDirectionSm="column" alignItemsSm="flex-end">
          <HistoryItemAmount color="text/success">
            + <Currency amount={amount} isShowPrecisionWithNullCents />
          </HistoryItemAmount>
          {formattedDate && <HistoryItemDesc>{formattedDate}</HistoryItemDesc>}
        </Col>
      </Row>
    </HistoryItemContainer>
  )
}

import styled from '@emotion/styled'
import { Body14 } from '@platform-ui/typography'

export const DetalItem = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '12px 0',
  ':first-of-type': {
    paddingTop: 12,
  },
  ':not(:first-of-type)': {
    borderTop: `1px solid ${theme.color['surface/bravo']}`,
  },
}))

export const DetailName = styled(Body14)(({ theme }) => ({
  color: theme.color['text/secondary'],
}))

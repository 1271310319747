import styled from '@emotion/styled'
import { Shimmer } from '@platform-ui/components/Shimmer'

export const ShimmerHeader = styled(Shimmer)(({ theme }) => ({
  marginBottom: 24,
  height: 20,
  width: 200,
  borderRadius: 10,

  [`${theme.media.md}`]: {
    marginBottom: 40,
    height: 24,
    width: 240,
  },
}))

export const ShimmerItemWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  marginBottom: 24,

  [`${theme.media.md}`]: {
    marginBottom: 32,
  },
}))

export const ShimmerImg = styled(Shimmer)(() => ({
  borderRadius: 20,
  height: 40,
  width: 40,
}))

export const ShimmerTextWrapper = styled.div(() => ({
  flexGrow: 1,
  paddingLeft: 12,
}))

export const ShimmerTextItem = styled.div(() => ({
  display: 'flex',
  flexBasis: '90%',
  justifyContent: 'space-between',
}))

export const ShimmerTitle = styled(Shimmer)(() => ({
  borderRadius: 10,
  height: 16,
  width: 104,
}))

export const ShimmerDesc = styled(Shimmer)(() => ({
  borderRadius: 10,
  height: 12,
  width: 64,
  marginTop: 10,
}))

export const ShimmerButton = styled(Shimmer)(() => ({
  borderRadius: 12,
  height: 56,
  width: '100%',
}))

import React from 'react'
import styled from '@emotion/styled'
import { Shimmer } from '@platform-ui/components/Shimmer'

export const NextPaymentTitleShimmer = styled(Shimmer)(({ theme }) => {
  return {
    borderRadius: 12,
    height: 24,
    width: 165,
    margin: '24px 16px',

    [`${theme.media.md}`]: {
      width: 240,
      margin: 32,
    },
  }
})

export const NextPaymentDateTitleShimmer = styled(Shimmer)(({ theme }) => {
  return {
    borderRadius: 6,
    height: 12,
    width: 100,
    margin: '10px 16px',

    [`${theme.media.md}`]: {
      margin: '12px 32px',
    },
  }
})

export const NextPaymentDateDescShimmer = styled(Shimmer)(({ theme }) => {
  return {
    borderRadius: 12,
    height: 24,
    width: 260,
    margin: '10px 16px',

    [`${theme.media.md}`]: {
      margin: '12px 32px',
    },
  }
})

export const NextPaymentWidgetShimmer = styled.div(({ theme }) => {
  return {
    borderRadius: 20,
    backgroundColor: '#F5F5F7',
    padding: 16,
    margin: '24px 0',

    [`${theme.media.md}`]: {
      borderRadius: 28,
      padding: 32,
      margin: '32px 0 48px',
    },
  }
})

export const NextPaymentWidgetContent = styled.div(({ theme }) => {
  return {
    display: 'flex',
    flexWrap: 'wrap',

    [`& > div`]: {
      marginRight: 40,
    },

    [`${theme.media.md}`]: {
      [`& > div:last-of-type`]: {
        marginRight: 0,
        marginLeft: 'auto',
      },
    },
  }
})

export const NextPaymentWidgetTitleShimmer = styled(Shimmer)<{ isLastBlock: boolean }>(
  ({ theme, isLastBlock }) => {
    return {
      borderRadius: 12,
      height: 24,
      width: 110,
      marginBottom: 16,

      [`${theme.media.md}`]: {
        marginBottom: 32,
        marginLeft: isLastBlock ? 'auto' : 0,
      },
    }
  }
)

export const NextPaymentWidgetDescShimmer = styled(Shimmer)<{ isLastBlock: boolean }>(
  ({ theme, isLastBlock }) => {
    return {
      borderRadius: 6,
      height: 12,
      width: 100,
      marginBottom: 8,

      [`${theme.media.md}`]: {
        width: isLastBlock ? 160 : 100,
      },
    }
  }
)

export const NextPaymentWidgetButtonShimmer = styled(Shimmer)(() => {
  return {
    borderRadius: 12,
    height: 40,
    marginTop: 4,
  }
})
const CONTENT_BLOCK_COUNTER = 3

export const NextPaymentShimmer = () => {
  return (
    <>
      <NextPaymentTitleShimmer />
      <NextPaymentDateTitleShimmer />
      <NextPaymentDateDescShimmer />

      <NextPaymentWidgetShimmer>
        <NextPaymentWidgetContent>
          {Array.from({ length: CONTENT_BLOCK_COUNTER }).map((_, number) => (
            <div key={number}>
              <NextPaymentWidgetDescShimmer isLastBlock={number === CONTENT_BLOCK_COUNTER - 1} />
              <NextPaymentWidgetTitleShimmer isLastBlock={number === CONTENT_BLOCK_COUNTER - 1} />
            </div>
          ))}
        </NextPaymentWidgetContent>

        <NextPaymentWidgetButtonShimmer />
      </NextPaymentWidgetShimmer>
    </>
  )
}

import React from 'react'
import { GetInstalmentScheduleModel } from '@/api/payment/getInstalmentSchedule'

interface useCalcAmountProps {
  detail: GetInstalmentScheduleModel.Detail
  debt: GetInstalmentScheduleModel.Debt
}

const statusType = GetInstalmentScheduleModel.InstalmentStatus
export const useCalcAmount: React.FC<useCalcAmountProps> = (detail, debt) => {
  const calcAmount = (detail, type, statuses, property) => {
    const isRequiredStatus = (status) => {
      return statuses ? statuses.includes(status) : false
    }

    return detail.reduce((acc, current) => {
      if (current.instalmentType === type && isRequiredStatus(current.instalmentStatus)) {
        return acc + current[property]
      }
      return acc
    }, 0)
  }

  const totalAmount = debt.totalDebtAmount

  const amounts = {
    main: calcAmount(
      detail,
      1,
      [statusType.UnPaid, statusType.Future, statusType.Pending],
      'debtMain'
    ),
    interest: calcAmount(
      detail,
      1,
      [statusType.UnPaid, statusType.Future, statusType.Pending],
      'interestSum'
    ),
    duty: calcAmount(
      detail,
      4,
      [statusType.UnPaid, statusType.Future, statusType.Pending],
      'instalmentAmount'
    ),
    losses: calcAmount(
      detail,
      3,
      [statusType.UnPaid, statusType.Future, statusType.Pending],
      'instalmentAmount'
    ),
    fines: calcAmount(
      detail,
      2,
      [statusType.UnPaid, statusType.Future, statusType.Pending],
      'instalmentAmount'
    ),
    other:
      calcAmount(
        detail,
        5,
        [statusType.UnPaid, statusType.Future, statusType.Pending],
        'instalmentAmount'
      ) + debt.debtCommissionAct,
  }

  return {
    totalAmount,
    amounts,
  }
}

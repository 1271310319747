import React from 'react'
import { Headline16 } from '@platform-ui/typography'
import { Currency } from '@/components/Currency'
import {
  CreditDetailsWrapper,
  CreditDetalsItem,
  CreditLastTransaction,
  CreditDetailSecondary,
} from './styled'
import { formatDate } from '@/utils/formatDate'
import { DetailsItem } from '@/components/DetailsItem'
import { ClientProductsModel } from '@/api/products/clientProducts'

export interface PaymentScheduleProps {
  insurance: boolean
  insuranceName: string
  finProtection: boolean
  postingDate: string
  transactionAmount: number
  product: Pick<
    ClientProductsModel.CreditLoan,
    | 'contractNumber'
    | 'productName'
    | 'productTypeNumber'
    | 'creditAmount'
    | 'accountNumber'
    | 'dateSign'
    | 'smsPackService'
    | 'accountBalance'
  >
  isSoldOutCredit?: boolean
}

export const DialogCreditDetailsSuccess = ({
  finProtection,
  insuranceName,
  insurance,
  postingDate,
  transactionAmount,
  product,
  isSoldOutCredit,
}: PaymentScheduleProps) => {
  const toFormatDate = (date: string) => {
    if (isNaN(Date.parse(date))) {
      return null
    }
    const parse = new Date(Date.parse(date))

    return formatDate(parse, 'DD.MM.YYYY')
  }

  return (
    <>
      <CreditDetailsWrapper>
        <CreditDetalsItem>
          <Headline16>Информация по кредиту</Headline16>
          <DetailsItem title="Наименование кредита">
            <Headline16>{product.productName}</Headline16>
          </DetailsItem>
          <DetailsItem title="Сумма кредита">
            <Headline16>
              <Currency amount={product.creditAmount} isShowPrecisionWithNullCents />
            </Headline16>
          </DetailsItem>
        </CreditDetalsItem>
        <CreditDetalsItem>
          <Headline16>Информация о счёте</Headline16>
          <DetailsItem title="Номер счёта">
            <Headline16>{product.accountNumber}</Headline16>
          </DetailsItem>
          <DetailsItem title="Остаток на счёте">
            <Headline16>
              <Currency amount={product.accountBalance} isShowPrecisionWithNullCents />
            </Headline16>
          </DetailsItem>
          <DetailsItem title="Последнее поступление средств">
            <CreditLastTransaction>
              <Headline16>
                <Currency amount={transactionAmount} isShowPrecisionWithNullCents />
              </Headline16>
              <CreditDetailSecondary>{toFormatDate(postingDate)}</CreditDetailSecondary>
            </CreditLastTransaction>
          </DetailsItem>
        </CreditDetalsItem>
        <CreditDetalsItem>
          <Headline16>Информация о договоре</Headline16>
          <DetailsItem title="Номер договора">
            <Headline16>{product.contractNumber}</Headline16>
          </DetailsItem>
          <DetailsItem title="Дата подписания">
            <Headline16>{toFormatDate(product.dateSign)}</Headline16>
          </DetailsItem>
        </CreditDetalsItem>
        {!isSoldOutCredit && (
          <CreditDetalsItem>
            <Headline16>Услуги</Headline16>
            <DetailsItem title="Страховка">
              <Headline16>{insurance ? 'Подключена' : 'Отключена'}</Headline16>
            </DetailsItem>
            {insuranceName && (
              <DetailsItem title="Тип страховки">
                <Headline16>{insuranceName}</Headline16>
              </DetailsItem>
            )}
            <DetailsItem title="Финансовая защита">
              <Headline16>{finProtection ? 'Подключена' : 'Отключена'}</Headline16>
            </DetailsItem>
            <DetailsItem title="СМС-пакет">
              <Headline16>{product.smsPackService ? 'Подключен' : 'Не подключен'}</Headline16>
            </DetailsItem>
          </CreditDetalsItem>
        )}
      </CreditDetailsWrapper>
    </>
  )
}

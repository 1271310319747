import React from 'react'
import { Currency } from '@/components/Currency'
import { Headline16 } from '@platform-ui/typography'
import { ListItem, PaymentType, PaymentAmount } from './styled'
import { ClientProductsModel } from '@/api/products/clientProducts'

export interface DialogCreditPaymentContentProps {
  debtAmount: number
  earlyPaymentSum: number
  earlyPaymentType: number
  penaltySum: number
  contractStatus: number
  payment: number
  isUncertainPaymentSum: boolean
  creditLoanGuiData: ClientProductsModel.CreditLoanGuiData
}

export const DialogCreditPaymentContent = ({
  debtAmount,
  creditLoanGuiData,
  earlyPaymentType,
  earlyPaymentSum,
  penaltySum,
  contractStatus,
  payment,
  isUncertainPaymentSum,
}: DialogCreditPaymentContentProps) => {
  const {
    creditLoanGuiStatus,
    debtCommissionAct,
    debtBankLostAct,
    debtStateDutyAct,
    debtOtherIncomeAct,
  } = creditLoanGuiData

  const contentList: { name: string; amount: number; condition: boolean }[] = [
    {
      name: 'Ежемесячный платёж',
      amount: earlyPaymentType === 0 ? 0 : payment,
      condition: contractStatus !== 5,
    },
    {
      name: 'Основной долг',
      amount: earlyPaymentType === 0 ? 0 : debtAmount,
      condition: contractStatus === 5,
    },
    {
      name: 'Просроченная задолженность',
      amount: earlyPaymentType === 0 ? 0 : debtAmount,
      condition: contractStatus !== 5,
    },
    {
      name: 'Сумма досрочного погашения',
      amount: earlyPaymentSum,
      condition: (earlyPaymentType === 0 || earlyPaymentType == 1) && creditLoanGuiStatus !== 21,
    },
    {
      name: 'Штрафы',
      amount: penaltySum,
      condition: true,
    },
    { name: 'Госпошлина', amount: debtStateDutyAct, condition: contractStatus === 5 },
    { name: 'Убытки', amount: debtBankLostAct, condition: contractStatus === 5 },
    { name: 'Иные платежи', amount: debtOtherIncomeAct, condition: contractStatus === 5 },
    { name: 'Комиссии', amount: debtCommissionAct, condition: contractStatus === 5 },
  ]

  if (isUncertainPaymentSum) {
    return null
  }

  return (
    <>
      <Headline16>Из чего состоит:</Headline16>
      <div>
        {contentList.map(({ name, amount, condition }) => {
          if (!condition) {
            return null
          } else
            return (
              <ListItem key={name}>
                <PaymentType>{name}</PaymentType>
                <PaymentAmount>
                  <Currency amount={amount} isShowPrecisionWithNullCents />
                </PaymentAmount>
              </ListItem>
            )
        })}
      </div>
    </>
  )
}

import { useEffect } from 'react'
import {
  useGetInfoInterbankTransferMutation,
  GetInfoInterbankTransferModel,
} from '@/api/payment/getInfoInterbankTransfer'
import { ResponseType } from '@/models/ResponseType'

export const useDialogBankDetailsState = ():
  | {
      status: 'idle' | 'loading' | 'error'
      data: null
      retry?: () => void
    }
  | {
      status: 'success'
      data: GetInfoInterbankTransferModel.ResolveWithDataResponse
    } => {
  const { status, data, mutateAsync } = useGetInfoInterbankTransferMutation()

  useEffect(() => {
    mutateAsync({})
  }, [])

  if (status === 'success' && data?.data?.type === ResponseType.ResolveWithData) {
    return {
      status,
      data: data.data,
    }
  }

  return {
    status,
    data: null,
    retry: () => mutateAsync({}),
  }
}

import styled from '@emotion/styled'
import { Body14 } from '@platform-ui/typography'

export const DialogBankDetailsCell = styled.div(({ theme }) => {
  return {
    boxSizing: 'border-box',
    border: `1px solid ${theme.color['surface/bravo']}`,
    margin: '-1px -1px 0 0',
    width: '100%',
    padding: '15px 16px',
    position: 'relative',

    ':first-of-type': {
      borderRadius: '16px 16px 0 0',
    },
    ':last-child': {
      borderRadius: '0 0 16px 16px',
    },
  }
})

export const DialogBankDetailsTitle = styled(Body14)(({ theme }) => {
  return {
    marginBottom: 4,
    color: theme.color['text/secondary'],
  }
})

import { ResponseType } from '@/models/ResponseType'
import { RejectedWithSystemErrorResponse } from '@/models/RejectedWithSystemErrorResponse'
import { HttpClient } from '@/core'
import { AdapterModel } from './AdapterModel'

export const adapter: AdapterModel.Func = (data) => {
  const errorData = data as AdapterModel.ResponseDataError
  /**
   * Сервер всегда возвращает status=200 в заголовке
   * Реальный статус приходит в теле ответа
   */
  if (errorData?.StatusCode !== 200) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors: errorData.ErrorDetails
        ? errorData.ErrorDetails.map(({ Error }) => ({
            type: Error,
            message: Error,
          }))
        : [],
    }
    throw HttpClient.createError(errorData?.StatusCode, exception)
  }

  try {
    const errorData = data as AdapterModel.ResponseDataError
    /**
     * Есть ошибки от сервера
     */
    if (errorData.ErrorDetails && errorData.ErrorDetails.length) {
      return {
        type: ResponseType.ResolveWithSystemError,
        systemErrors: errorData.ErrorDetails.map(({ Error }) => ({
          type: Error,
          message: Error,
        })),
      }
    }

    const successData = data as AdapterModel.ResponseDataSuccess

    return {
      type: ResponseType.ResolveWithData,
      payments: successData.Result
        ? successData.Result.sort((a, b) => {
            /**
             * Сортируем по полю date
             */
            if (a.Date > b.Date) {
              return -1
            }
            return 0
          }).map((item) => ({
            amount: item.Amount,
            date: item.Date,
          }))
        : [],
    }
  } catch (error) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors: [{ message: 'Ошибка при обработке полученных данных', type: 'ADAPTER_ERROR' }],
    }

    throw HttpClient.createError(500, exception)
  }
}

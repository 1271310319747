import styled from '@emotion/styled'
import { Theme } from '@emotion/react'

export const RequirementWrapper = styled.div(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: 16,
    padding: 32,
    width: '100%',
    minHeight: '180px',
    background: '#383B4F',
    borderRadius: '12px',
  }
})

export const MainTitle = styled.h1(({ theme }) => ({
  ...theme.typography['headline/20'],
  textAlign: 'center',
  margin: `0 0 24px 0`,
  padding: '0 12px',
  color: 'white',
  cursor: 'default',
  userSelect: 'none',

  [`${theme.media.md}`]: {
    ...theme.typography['headline/24 Main'],
    margin: `0 0 32px 0`,
  },
}))

export const ItemTitle = styled.p(({ theme }) => {
  return {
    ...theme.typography['headline/16'],
    color: 'white',
  }
})

export const TotalDebtWrapper = styled.div(() => {
  return {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    background: '#383B4F',
    borderRadius: '12px',
    cursor: 'pointer',
  }
})

export const RightContent = styled.div(() => {
  return {
    display: 'flex',
  }
})

export const SumText = styled.p(({ theme }) => {
  return {
    ...theme.typography['headline/16'],
    color: 'white',
    userSelect: 'none',
  }
})

export const MainSum = styled.p(({ theme }) => ({
  ...theme.typography['headline/24'],
  textAlign: 'center',
  color: 'white',
  userSelect: 'none',
}))

export const ScheduleArrow = styled.div<{ isOpen: boolean }>(({ isOpen }) => ({
  color: 'white',
  position: 'relative',
  display: 'flex',
  flexShrink: 0,
  paddingLeft: '12px',

  ['& > div']: {
    width: 28,
    height: 28,
  },

  ['& > *']: {
    transition: 'transform 200ms linear',
    transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
  },
}))

export const ListItem = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignContent: 'center',
  padding: '16px 0',
  gap: 10,

  ':first-of-type': {
    marginTop: 16,
    borderTop: `1px solid rgba(255, 255, 255, 0.12)`,
  },

  '&:not(:last-child)': {
    borderBottom: `1px solid rgba(255, 255, 255, 0.12)`,
  },
}))

export const BottomContent = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignContent: 'center',
  alignItems: 'center',
  gap: '12px',

  [`> p`]: {
    ...theme.typography['Body/16'],
    color: 'white',
    textAlign: 'left',
    margin: 0,
    ':last-child': {
      ...theme.typography['headline/16'],
      color: 'white',
      whiteSpace: 'nowrap',
    },
  },
}))

export const AppealText = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginTop: 16,
  alignContent: 'center',
  textAlign: 'center',
  gap: 8,

  [`> p`]: {
    ...theme.typography['Body/16 Main body'],
    color: 'white',
    textAlign: 'center',
    margin: 0,
  },
  [`> a`]: {
    ...theme.typography['headline/24'],
    textDecoration: 'none',
    color: 'white',
    textAlign: 'center',
    margin: 0,
  },
}))

import styled from '@emotion/styled'
import { Body16 } from '@platform-ui/typography'

export const StyledWrapper = styled.div(({ theme }) => ({
  padding: 16,
  background: theme.color['icons/main'],
  borderRadius: '20px',
  minWidth: 212,
  marginBottom: 32,

  [`${theme.media.md}`]: {
    padding: 32,
    minWidth: 360,
  },

  '& > p > div > a': {
    textDecoration: 'none',
    color: theme.color['text/primary'],
  },
}))

export const Headline = styled.h1(({ theme }) => ({
  ...theme.typography['headline/28'],
  color: 'white',
}))

export const MainContent = styled(Body16)(({ theme }) => ({
  color: 'white',
  marginTop: 24,
  marginBottom: 24,
}))

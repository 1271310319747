import React from 'react'
import { BankDetailsShimmer } from '@/components/BankDetailsShimmer'
import { useDialogBankDetailsState } from './useDialogBankDetailsState'
import { DialogBankDetailsSuccess } from './DialogBankDetailsSuccess'
import { ErrorDataLoadingModal } from '@/components/ErrorDataLoadingModal'
import { BaseModal } from '@platform-ui/components'
import { useAppContext } from '@/core'
import { handleShare } from '@/utils/handleShare'
import { AppContext } from '@/models/AppContext'
import { GetInfoInterbankTransferModel } from '@/api/payment/getInfoInterbankTransfer'
import { observer } from 'mobx-react-lite'

export type SuccessInfoProps = {
  isSuccess: boolean
  data?: GetInfoInterbankTransferModel.ResolveWithDataResponse
  accountNumber: string
}

const useSuccessInfo = ({
  isSuccess,
  data,
  accountNumber,
}: SuccessInfoProps): {
  getBankDetailsInfo: string
  clientFio: string
  bankName: string
  corrAcc: string
  bankNum: string
  bankINN: string
} => {
  if (!isSuccess || !data) return null

  const { clientFio, bankName, corrAcc, bankNum, bankINN } =
    data as GetInfoInterbankTransferModel.ResolveWithDataResponse
  const arr: {
    title: string
    description: string
  }[] = [
    {
      title: 'Получатель',
      description: clientFio,
    },
    {
      title: 'Счёт',
      description: accountNumber,
    },
    {
      title: 'Банк получателя',
      description: bankName,
    },
    {
      title: 'Корреспондентский счёт',
      description: corrAcc,
    },
    {
      title: 'БИК',
      description: bankNum,
    },
    {
      title: 'ИНН',
      description: bankINN,
    },
  ]

  const getBankDetailsInfo = arr.reduce((prev, curr) => {
    prev = `${prev}\n${curr.title}\n${curr.description}`
    return prev.trim()
  }, '')

  return {
    getBankDetailsInfo,
    clientFio,
    bankName,
    corrAcc,
    bankNum,
    bankINN,
  }
}

export type ButtonInfoProps = {
  isError: boolean
  isSuccess: boolean
  retry?: () => void
  getBankDetailsInfo: string
  context: AppContext
}

const useButtonInfo = ({
  isError,
  isSuccess,
  retry,
  getBankDetailsInfo,
  context,
}: ButtonInfoProps): {
  title?: string
  onClick?: () => void
} => {
  if (isError) {
    return {
      title: 'Попробовать ещё раз',
      onClick: retry,
    }
  }
  if (isSuccess) {
    return {
      title: 'Поделиться',
      onClick: () => handleShare(getBankDetailsInfo, context),
    }
  }
  return {
    title: null,
    onClick: null,
  }
}

export interface DialogBankDetailsProps {
  accountNumber: string
  isOpen: boolean
  onClose: () => void
  title: string
}

export const DialogBankDetails = ({
  accountNumber,
  isOpen,
  onClose,
  title,
}: DialogBankDetailsProps) => {
  const dialogBankState = useDialogBankDetailsState()

  const context = useAppContext<AppContext>()

  const isLoading = dialogBankState.status === 'loading'
  const isError = dialogBankState.status === 'error'
  const isSuccess = dialogBankState.status === 'success'

  const successInfo = useSuccessInfo({
    isSuccess,
    data: dialogBankState?.data,
    accountNumber,
  })
  const buttonInfo = useButtonInfo({
    isError,
    isSuccess,
    retry: isError && dialogBankState.retry,
    getBankDetailsInfo: successInfo?.getBankDetailsInfo,
    context,
  })

  if (isLoading || isError || isSuccess) {
    return (
      <BaseModal
        isOpen={isOpen}
        onClose={onClose}
        title={title}
        isPending={isLoading}
        primaryBtnTitle={buttonInfo?.title}
        primaryBtnClick={buttonInfo?.onClick}
      >
        {isLoading && <BankDetailsShimmer />}
        {isError && (
          <ErrorDataLoadingModal description="Не удалось загрузить реквизиты счёта. Попробуйте ещё раз или повторите попытку позже" />
        )}
        {isSuccess && (
          <DialogBankDetailsSuccess
            isOpen={isOpen}
            onClose={onClose}
            title={title}
            clientFio={successInfo.clientFio}
            bankName={successInfo.bankName}
            corrAcc={successInfo.corrAcc}
            bankNum={successInfo.bankNum}
            bankINN={successInfo.bankINN}
            accountNumber={accountNumber}
          />
        )}
      </BaseModal>
    )
  }

  return null
}

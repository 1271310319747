import { HttpClientModel } from '@/core'
import { ResponseType } from '@/models/ResponseType'
import { ResolveWithSystemErrorResponse } from '@/models/ResolveWithSystemErrorResponse'
import { RejectedWithSystemErrorResponse } from '@/models/RejectedWithSystemErrorResponse'

export namespace GetInstalmentScheduleModel {
  export type Func = (props: Props) => Promise<Response>
  export interface Props {
    contractNumber: string
    apiVersion: string
    isEarly: boolean
  }

  export type Response = HttpClientModel.Response<ResponseData>
  export type ErrorResponse = HttpClientModel.ErrorResponse<ErrorResponseData>
  export interface ResolveWithDataResponse {
    type: ResponseType.ResolveWithData
    paymentNum: number
    unPaidPaymentNum?: number
    lastPaymentNum?: number
    debt: Debt
    detail: Detail[]
  }

  export interface Debt {
    outstandingDebtAmount: number
    penaltyAmount: number
    totalDebtAmount: number
    debtCommissionAct?: number
  }

  export interface Detail {
    instalmentNumber: number
    dueDateTicks: number
    instalmentAmount: number
    datePaidTicks: number
    amountPaid: number
    instalmentStatus: InstalmentStatus
    instalmentType: number
    debtMain?: number
    interestSum?: number
    serviceAmount: number
    // Custom props
    dueDateFormat: string
  }

  export enum InstalmentStatus {
    NotSet,
    Paid,
    UnPaid,
    Future,
    Pending,
  }

  export type ResponseData = ResolveWithDataResponse | ResolveWithSystemErrorResponse
  export type ErrorResponseData = RejectedWithSystemErrorResponse
  export interface RequestData {
    contractNumber: string
  }
  export interface RequestHeaders {
    _ver_: string
    _os_: string
  }
}
